import { useEffect, useState } from "react";
import { Heading, Container, LineSpace } from "../../global";
import Description from "../description/Description";
import MidBar from "../multimidbar/MidBar";
import { Blink, LoadingPage, } from "./styles";

const loading = ({gameSettings}) => {
    return (
        <LoadingPage>
            <Container $flex={1} $flexDirection={"column"} $gap={"5px"} style={{transform: "rotate(180deg)"}}>
                <Container $flex={.5} $flexDirection={"column"}>
                    <Heading $size={"26px"}>{gameSettings.title}</Heading> 
                </Container>
                <Container $flex={2} $flexDirection={"column"}>
                    <Description gameSettings={gameSettings}/>
                </Container>
                <Container $flex={2}>
                    <Heading $size={"20px"}>Loading</Heading>
                    <Blink $size={"20px"} $delay={0}>.</Blink>
                    <Blink $size={"20px"} $delay={0.25}>.</Blink>
                    <Blink $size={"20px"} $delay={0.5}>.</Blink>
                </Container>
            </Container>
                <LineSpace/>
                <MidBar navigateTo={"/multiplayer"}/>
                <LineSpace/>
            <Container $flex={1} $flexDirection={"column"} $gap={"5px"} style={{transform: "rotate(360deg)"}}>
                <Container $flex={.5} $flexDirection={"column"}>
                    <Heading $size={"26px"}>{gameSettings.title}</Heading> 
                </Container>
                <Container $flex={2} $flexDirection={"column"}>
                    <Description gameSettings={gameSettings}/>
                </Container>
                <Container $flex={2}>
                    <Heading $size={"20px"}>Loading</Heading>
                    <Blink $size={"20px"} $delay={0}>.</Blink>
                    <Blink $size={"20px"} $delay={0.25}>.</Blink>
                    <Blink $size={"20px"} $delay={0.5}>.</Blink>               
                </Container>
            </Container>
        </LoadingPage>
    )
}

const loaded = ({readyPlayer1, readyPlayer2, handleReady1, handleReady2, gameSettings}) => {
    return (
        <LoadingPage>
            <Container $flex={1} onClick={handleReady2} onTouchStart={handleReady2} $flexDirection={"column"} $gap={"5px"} style={{transform: "rotate(180deg)"}}>
                <Container $flex={.5} $flexDirection={"column"}>
                    <Heading $size={"26px"}>{gameSettings.title}</Heading> 
                </Container>
                <Container $flex={2} $flexDirection={"column"}>
                    <Description gameSettings={gameSettings}/>
                </Container>
                <Container $flex={2} $flexDirection={"column"}>
                    {readyPlayer2 && <Heading $size={"20px"}>Red Ready!</Heading>}
                    {!readyPlayer2 && <Blink $size={"20px"}>Click or tap to continue...</Blink>}
                </Container>
            </Container>
                <LineSpace/>
                <MidBar navigateTo={"/multiplayer"}/>
                <LineSpace/>
            <Container $flex={1} onClick={handleReady1} onTouchStart={handleReady1} $flexDirection={"column"} $gap={"5px"} style={{transform: "rotate(360deg)"}}>
                <Container $flex={.5} $flexDirection={"column"}>
                    <Heading $size={"26px"}>{gameSettings.title}</Heading> 
                </Container>
                <Container $flex={2} $flexDirection={"column"}>
                    <Description gameSettings={gameSettings}/>
                </Container>
                <Container $flex={2} $flexDirection={"column"}>
                    {readyPlayer1 && <Heading $size={"20px"}>Blue Ready!</Heading>}
                    {!readyPlayer1 && <Blink $size={"20px"}>Click or tap to continue...</Blink>}
                </Container>
            </Container>
        </LoadingPage>
    )
}

const LoadingMulti = ({dataReady, onClick, gameSettings}) => {
    const [readyPlayer1, setReadyPlayer1] = useState(false)
    const [readyPlayer2, setReadyPlayer2] = useState(false)
    function handleReady1() {
        setReadyPlayer1(true)
    }
    function handleReady2() {
        setReadyPlayer2(true)
    }
    useEffect(() => {
        if (readyPlayer1 && readyPlayer2) onClick()
        return () => {}
    },[readyPlayer1, readyPlayer2])
    return dataReady ? loaded({readyPlayer1, handleReady1, readyPlayer2, handleReady2, gameSettings}) : loading({gameSettings})
}

export default LoadingMulti;