import { OtherTileButton } from "./styles";

const Reset = ({
    disabled,
    isMobile,
    handleClick,
    $multi,
}) => {
    return(
        <OtherTileButton
            $multi={$multi}
            disabled={disabled}
            onClick={isMobile ? undefined : handleClick}
            onTouchStart={isMobile && !disabled ? handleClick : undefined}
        >
            RESET
        </OtherTileButton>
    )
}

export default Reset;