import { useEffect } from "react";
import NumTile from "./numtile/NumTile";
import { ContainerGameboard, ContainerNumTiles, ContainerOpTiles, ContainerOtherTiles } from "./styles";
import OpTile from "./optile/OpTile";
import { opTileClean, updatePlayerClicked } from "../../global/utils/utils";
import { OpContainer, OpIndicator } from "./optile/styles";
import Reset from "./othertiles/Reset";
import Undo from "./othertiles/Undo";

const Gameboard = ({
    player,
    equationInfo, 
    disabled,
    isMobile,
    keyboardMap,
    numTileInfo, 
    numTileClicked,
    opUsed,
    opTileClicked,
    UNDO_TILES,
    UPDATE_EQUATION,
    RESET_TILES,
}) => { 
    const numTiles = numTileInfo.map((tile, index) => (
        <NumTile 
            key={tile.id} 
            disabled={disabled}
            {...numTileInfo[tile.id]} 
            handleClick={() => UPDATE_EQUATION({tile})}
            clicked={numTileClicked[index]}
            isMobile={isMobile}
        />
    ))
    const opTiles = Object.keys(opTileClean).map((op, index) => (
        <OpContainer key={op}>
            <OpTile 
                disabled={disabled}
                {...opTileClean[op]}
                handleClick={() => UPDATE_EQUATION({tile: op})}
                clicked={opTileClicked[index]}
                isMobile={isMobile}
            />
            <OpIndicator $used={opUsed[index]} $overFour={opUsed.filter((a) => a>0).length}/>
        </OpContainer>
    ))
    const allClicked = {numTileClicked, opTileClicked}
    if (player) updatePlayerClicked({player, clicked: allClicked})

    function handleKeyPress(event) {
        let tilePressed = ""     
        if (!disabled && event.code in keyboardMap.numTiles) {
            tilePressed = keyboardMap.numTiles[event.code]
            if (numTileInfo[tilePressed].value !== "empty") {
                UPDATE_EQUATION({tile: numTileInfo[tilePressed]})
            }
        } else if (!disabled && event.code in keyboardMap.opTiles) {
            tilePressed = keyboardMap.opTiles[event.code]
            UPDATE_EQUATION({tile: tilePressed})
        } else if (!disabled && event.code in keyboardMap.otherTiles) {
            tilePressed = keyboardMap.otherTiles[event.code]
            handleClickOtherTile(tilePressed)
        }
    }

    function handleClickOtherTile(tile) {
        if (tile === "resetTile") {
            RESET_TILES()
        } else if (tile === "undoTile") {
            UNDO_TILES()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress)
        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    }, [equationInfo, disabled])

    return ( 
        <ContainerGameboard $flexDirection={"column"} $flex={1}>
            <ContainerNumTiles $flexWrap={"wrap"} $flex={1}>
                {numTiles}
            </ContainerNumTiles>
            <ContainerOpTiles $flex={1}>
                {opTiles}
            </ContainerOpTiles>
            <ContainerOtherTiles $flex={1}>
                <Reset disabled={disabled} isMobile={isMobile} handleClick={() => handleClickOtherTile("resetTile")}/>
                <Undo disabled={disabled} isMobile={isMobile} handleClick={() => handleClickOtherTile("undoTile")}/>
            </ContainerOtherTiles>
        </ContainerGameboard>
    )
}

export default Gameboard;