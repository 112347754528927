import { useContext, useEffect, useState } from "react";
import BotBar from "../../components/botbar/BotBar"
import { Container, Heading, Page, UserContext } from "../../global";
import DifficultyHS from "./DifficultyHS";
import GameSetting from "../../components/gamesetting/GameSetting";
import { ScoreContainer } from "./styles";
import { getAllHS, getAllHSUser } from "./utils";
import MessageModal from "../../components/messagemodal/MessageModal";

const Leaderboard = (props) => {
    const {user} = useContext(UserContext)
    const [allHS, setAllHS] = useState({ timeTrial: {e: [], m: [], h:[]}})
    const [userStats, setUserStats] = useState({ timeTrial: {e: {rank: 0, percentile: 0}, m: {rank: 0, percentile: 0}, h: {rank: 0, percentile: 0}}})
    const [gameSettings, setGameSettings] = useState({
        title: "Easy",
        difficulty: "e",
        mode: "timeTrial",
        modeString: "Time Trial"
    })
    const [modal, setModal] = useState({show: false, string: ""})

    const difficultySetting = {
        title: "All-Time Global Leaderboard",
        setting: "difficulty",
        buttons: [
            {
                text: "Easy",
                value: "e",
            },
            {
                text: "Normal",
                value: "m",
            },
            {
                text: "Hard",
                value: "h",
            },
        ]
    }

    useEffect(() => {
        if (user.username) {
            getAllHSUser({setAllHS, setUserStats, user})
        } else {
            getAllHS(setAllHS)
        }
        return () => {}
    }, [])

    useEffect(() => {
        if (modal.show) {
            setTimeout(() => {
                setModal(prevState => ({...prevState, show: false}))
            }, 8000);
        }
        return () => {}
    }, [modal.show])

    useEffect(() => {
        switch (gameSettings.difficulty) {
            case "e":
                setGameSettings(prevState => ({...prevState, title: "Easy"}))
                break;
            case "m":
                setGameSettings(prevState => ({...prevState, title: "Normal"}))
                break;
            case "h":
                setGameSettings(prevState => ({...prevState, title: "Hard"}))
                break;
        }
        return () => {}
    }, [gameSettings.difficulty])

    return (
        <Page>
            {modal.show && 
                <MessageModal>
                    {modal.string}
                </MessageModal>
            }
            <Container $flex={1} $flexDirection={"column"} $gap={"7px"}>
                <Container $flex={.25}></Container>
                <Container $flex={1}>
                    <GameSetting setGameSettings={setGameSettings} $active={gameSettings.difficulty} settings={difficultySetting}/>
                </Container>
                <Container>
                    <Heading $size={"20px"}>Top 100</Heading>
                </Container>
                <ScoreContainer $flex={5}>
                    <DifficultyHS 
                        user={user} userStats={userStats}
                        difficulty={gameSettings.difficulty}
                        title={gameSettings.title}
                        modeString={gameSettings.modeString}
                        mode={gameSettings.mode}
                        highscore={allHS[gameSettings.mode][gameSettings.difficulty]}
                        setAllHS={setAllHS} setModal={setModal}/>
                </ScoreContainer>
            </Container>
            <Container>
                <BotBar/>
            </Container>
        </Page>
    )
}

export default Leaderboard;