import styled, { keyframes } from "styled-components";
import { Heading, Page } from "../../global";

const LoadingPage = styled(Page)`
    background-color: ${props => props.theme.background};
    height: calc(100 * var(--vh));
    width: 100vw;
    z-index: 2;
    position: absolute;
`;

const blink = keyframes`
    0% {
        filter: opacity(0%);
    }
    100% {
        filter: opacity(100%);
    }
`;

const Blink = styled(Heading)`
    animation-name: ${blink};
    animation-delay: ${props => props.$delay ? props.$delay+"s" : "0s"};
    animation-duration: .75s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
`;

export {
    Blink,
    LoadingPage,
};