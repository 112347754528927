import styled, { keyframes } from "styled-components";
import { Container, TARGET_ANIMATION_TIMES } from "../../global";

const targetAnimation = (color) => keyframes`
    0% {
        scale: 2;
        transform: translateY(19vh);
        background-color: ${color};
        border-radius: 5px;
        width: 30vw;
    }
    33% {
        scale: 2;
        transform: translateY(21vh);
        background-color: ${color};
        border-radius: 5px;
        width: 30vw;
    }
    100% {
        scale: 1;
        border-radius: 5px;
        width: 90vw;
    }
`;

const TargetContainer = styled(Container)`
    z-index: 2;
    animation-name: ${props => targetAnimation(props.theme.modal)};
    animation-duration: ${TARGET_ANIMATION_TIMES.LENGTH}s;
`;

export {
    TargetContainer
};