import { styled } from "styled-components";

const TimerBar = styled.div`
    display: flex;
    min-height: 8px;
    max-height: 13px;
    height: 2vh;
    background-color: ${props => props.$timer > 5 ? props.theme.btn : props.theme.warning};
`;

export {
    TimerBar,
};