import { styled } from "styled-components";

const ContainerBackButton = styled.div`
    display: flex;
    height: inherit;
    width: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    position: ${props => props.$midBar === true ? "absolute" : "default"};
`;

export {
    ContainerBackButton
};