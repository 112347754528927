import { styled } from "styled-components";
import { colors } from "../../../global/styles/base";
import { Button, Container, Modal } from "../../../global/styles/styles";

const ChangeKeysModal = styled(Modal)`
    height: 25%;
    width: 60%;
    animation-duration: 0.5s;
`;

const KeyChangeNumTile = styled(Button)`
    background-color: ${props => props.$clicked === true ? colors.CLICKED_GRAY : props.theme.btn};
    color: ${props => props.$clicked === true ? props.theme.lightText : props.theme.darkText};
    min-height: 55px;
    font-size: 16px;
    max-height: 120px;
    height: 25%;
    min-width: 55px;
    max-width: 120px;
    width: 25%;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: ${props => props.$clicked === true ? colors.HOVERED_GRAY : props.theme.card };
        }
    }
`;

const KeyChangeOpTile = styled(Button)`
    background-color: ${props => props.$clicked === true ? colors.CLICKED_GRAY : props.theme.btn};
    color: ${props => props.$clicked === true ? props.theme.lightText : props.theme.darkText};
    min-height: 30px;
    font-size: 16px;
    max-height: 45px;
    height: 8%;
    min-width: 55px;
    max-width: 80px;
    width: 18%;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: ${props => props.$clicked === true ? colors.HOVERED_GRAY : props.theme.card };
        }
    }
`;

const KeyChangeOtherTile = styled(Button)`
    background-color: ${props => props.$clicked === true ? colors.CLICKED_GRAY : props.theme.btn};
    color: ${props => props.$clicked === true ? props.theme.lightText : props.theme.darkText};
    font-size: 16px;
    min-height: 30px;
    max-height: 45px;
    height: 8%;
    min-width: 55px;
    max-width: 100px;
    width: 18%;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: ${props => props.$clicked === true ? colors.HOVERED_GRAY : props.theme.card };
        }
    }
`;

const SettingGameboard = styled(Container)`
    width: 90%;
    max-width: 400px;
`;

const SpecialInput = styled.input`
    width: 100%;
    height: 100%;
    text-align: center;
    background: none;
    border: none;
`;

export {
    ChangeKeysModal,
    KeyChangeNumTile,
    KeyChangeOpTile,
    KeyChangeOtherTile,
    SettingGameboard,
    SpecialInput,
};