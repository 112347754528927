import { useEffect, useState } from "react";
import { Heading } from "../../global/styles";
import { Phrase } from "./styles";
import { getPhrase } from "./utils";

const Phrases = ({ $duration = 2, $size = "18px", phrases, score = 0, trigger}) => {
    const [ansText, setText] = useState()
    const [fade, setFade] = useState(false)
    const durMilliseconds = $duration * 1000
    useEffect(() => {
        setText(getPhrase(phrases))
        if (trigger || score > 0) setFade(true)
        // wait 2 seconds before making fade = false
        setTimeout(() => {
            if (score) {
                setFade(false);
            }
        }, durMilliseconds);
        return () => {}
    }, [score])

    return (
        <Phrase $trigger={trigger} $duration={$duration} $fade={fade}>
            <Heading $size={$size}>{(trigger || score > 0) && ansText}</Heading>
        </Phrase>
    )
}

export default Phrases;