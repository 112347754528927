import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { checkAns, useNumtiles } from "./utils";
import { BlueTheme, Container, Page, RedTheme, SETTINGS, TARGET_ANIMATION_TIMES } from "../../global/styles";
import { randomNumberToMax } from "../../global/utils";
import Target from "../../components/target/Target";
import { TargetContainer } from "./styles";
import Score from "../../components/score/Score";
import { ThemeContext } from "../../global";
import ScoreSound from "../../assets/sounds/score-sound.wav"
import GameEndSound from "../../assets/sounds/game-end.wav"
import MultiGameboard from "../../components/gameboard/MultiGameboard";
import MidBar from "../../components/multimidbar/MidBar";
import { MultiplayerEndModal } from "../../components/endmodal/MultiplayerEndModal";
import { ThemeProvider } from "styled-components";

const MultiTimeTrial = ({gameSettings, isMobile}) => {
    let tempTime = TARGET_ANIMATION_TIMES.LENGTH-.5
    const {theme} = useContext(ThemeContext)
    const sound = theme.sound
    const navigate = useNavigate()
    const [scoreSound] = useSound(ScoreSound, {soundEnabled: sound})
    const [gameEndSound] = useSound(GameEndSound, {soundEnabled: sound, volume: 0.25})
    const [startTimer, setStartTimer] = useState(false)
    const [timerFinished, setTimerFinished] = useState(null)    
    const {title, difficulty, time, difficultyString} = gameSettings
    const [winner, setWinner] = useState()
    const [showEndModal, setShowEndModal] = useState(false)
    
    const {
        state: {
            numTileInfo: numTileInfo1,
            ans: ans1,
            usedOp: usedOp1,
            numTileClicked: numTileClicked1,
            opTileClicked: opTileClicked1
        },
        NEW_TILES: NEW_TILES1,
        RESET_TILES: RESET_TILES1,
        UNDO_TILES: UNDO_TILES1,
        UPDATE_EQUATION: UPDATE_EQUATION1
    } = useNumtiles()
    
    const {
        state: {
            numTileInfo: numTileInfo2,
            ans: ans2,
            usedOp: usedOp2,
            numTileClicked: numTileClicked2,
            opTileClicked: opTileClicked2
        },
        NEW_TILES: NEW_TILES2,
        RESET_TILES: RESET_TILES2,
        UNDO_TILES: UNDO_TILES2,
        UPDATE_EQUATION: UPDATE_EQUATION2
    } = useNumtiles()

    const [score1, setScore1] = useState(0)
    const [target1, setTarget1] = useState(() => randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty}))
    const [disabled1, setDisabled1] = useState(true) 
    
    const [score2, setScore2] = useState(0)
    const [target2, setTarget2] = useState(() => randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty}))
    const [disabled2, setDisabled2] = useState(true) 

    useEffect(() => {
        NEW_TILES1({difficulty, target: target1})
        NEW_TILES2({difficulty, target: target2})
        return () => {}
    }, [])

    function handlePlayAgain() {
        setTimerFinished(false)
        setStartTimer(true)
        setShowEndModal(false)
        setDisabled1(false)
        setDisabled2(false)
        setScore1(0)
        setScore2(0)
        const tempTarget1 = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
        setTarget1(tempTarget1)
        NEW_TILES1({difficulty, target: tempTarget1})
        const tempTarget2 = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
        setTarget2(tempTarget2)
        NEW_TILES2({difficulty, target: tempTarget2})
    }

    function handleQuit() {
        if (isMobile && document.fullscreenElement) {
            document.exitFullscreen()
        }
        navigate("/")
    }

    useEffect(() => { 
        let timerId;
        timerId = setInterval(() => {
            tempTime -= .5
            if (tempTime <= 0) {
                clearInterval(timerId)
                setDisabled1(false)
                setDisabled2(false)
                setStartTimer(true)
            }
        }, 500);
        return () => {clearInterval(timerId)}
    }, [])

    useEffect(() => {
        if (ans1 === target1) {
            const increaseScore = checkAns({difficulty, numTileInfo: numTileInfo1, opUsed: usedOp1})
            if (increaseScore) {
                scoreSound()
                setScore1(prevState => prevState + 1)
                const tempTarget1 = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
                setTarget1(tempTarget1)
                NEW_TILES1({difficulty, target: tempTarget1})
            }
        }
        return () => {}
    }, [ans1])

    useEffect(() => {
        if (ans2 === target2) {
            const increaseScore = checkAns({difficulty, numTileInfo: numTileInfo2, opUsed: usedOp2})
            if (increaseScore) {
                scoreSound()
                setScore2(prevState => prevState + 1)
                const tempTarget2 = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
                setTarget2(tempTarget2)
                NEW_TILES2({difficulty, target: tempTarget2})
            }
        }
        return () => {}
    }, [ans2])

    useEffect(() => {
        if (timerFinished) {
            setDisabled1(true)
            setDisabled2(true)
            setStartTimer(false)
            setShowEndModal(true)
            gameEndSound()
            if (score1 === score2) {
                setWinner("It's a tie!")
            } else if (score1 > score2) {
                setWinner("Blue wins!")
            } else if (score2 > score1) {
                setWinner("Red wins!")
            }
        }
        return () => {}
    }, [timerFinished])

    return (
        <Page>
            {showEndModal && <MultiplayerEndModal 
                score1={score1}
                score2={score2}
                winner={winner}
                handlePlayAgain={handlePlayAgain}
                handleQuit={handleQuit}
            />}
            <Container $flex={1} $gap={"1vh"} $flexDirection={"column"} style={{transform: "rotate(180deg)"}}>
                <Container $flex={1}>
                    <Container $flex={1}>
                        <Score player={"Opponent"} $size={"2.5vh"} score={score1}/>
                    </Container>
                <TargetContainer $flex={1}>
                        <Target $size={"6.5vh"} target={target2}/>
                </TargetContainer>
                    <Container $flex={1}>
                        <Score player={"You"} $size={"2.5vh"} score={score2}/>
                    </Container>
                </Container>
                <Container $flex={6} style={{alignItems: "flex-start"}}>
                    <ThemeProvider theme={RedTheme.colors}>
                        <MultiGameboard
                            numTileInfo={numTileInfo2} numTileClicked={numTileClicked2}
                            opTileClicked={opTileClicked2} opUsed={usedOp2}
                            disabled={disabled2} RESET_TILES={RESET_TILES2} 
                            UNDO_TILES={UNDO_TILES2} UPDATE_EQUATION={UPDATE_EQUATION2}
                            isMobile={isMobile}
                        />
                    </ThemeProvider>
                </Container>
            </Container>
            <MidBar 
                $size={"10px"} 
                startTimer={startTimer} 
                time={time} 
                title={difficultyString + " - " + title} 
                navigateTo={"/multiplayer"}
                setTimerFinished={setTimerFinished}
                isMobile={isMobile}
            />
            <Container $flex={1} $gap={"1vh"} $flexDirection={"column"}  style={{transform: "rotate(360deg)"}}>
                <Container $flex={1}>
                    <Container $flex={1}>
                        <Score player={"Opponent"} $size={"2.5vh"} score={score2}/>
                    </Container>
                <TargetContainer $flex={1}>
                        <Target $size={"6.5vh"} target={target1}/>
                </TargetContainer>
                    <Container $flex={1}>
                        <Score player={"You"} $size={"2.5vh"} score={score1}/>
                    </Container>
                </Container>
                <Container $flex={6} style={{alignItems: "flex-start"}}>
                    <ThemeProvider theme={BlueTheme.colors}>
                        <MultiGameboard
                            numTileInfo={numTileInfo1} numTileClicked={numTileClicked1}
                            opTileClicked={opTileClicked1} opUsed={usedOp1}
                            disabled={disabled1} RESET_TILES={RESET_TILES1} 
                            UNDO_TILES={UNDO_TILES1} UPDATE_EQUATION={UPDATE_EQUATION1}
                            isMobile={isMobile}
                        />
                    </ThemeProvider>
                </Container>
            </Container>
        </Page>
    )
}

export default MultiTimeTrial;