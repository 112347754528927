import { useContext, useEffect, useState } from "react";
import { checkAns, useNumtiles } from "./utils";
import { Container, Heading, Page, SETTINGS, TARGET_ANIMATION_TIMES } from "../../global/styles";
import { getSolution, randomNumberToMax } from "../../global/utils";
import TopBar from "../../components/topbar/TopBar";
import Gameboard from "../../components/gameboard/Gameboard";
import Target from "../../components/target/Target";
import Phrases from "../../components/phrases/Phrases";
import { TargetContainer } from "./styles";
import { inGamePhrases } from "../../components/phrases/utils";
import ScoreSound from "../../assets/sounds/score-sound.wav"
import useSound from "use-sound";
import { ThemeContext } from "../../global";

const Practice = ({gameSettings, isMobile}) => {
    let tempTime = TARGET_ANIMATION_TIMES.LENGTH-.5
    let showSolutionTextTime = 10 
    const {theme} = useContext(ThemeContext)
    const {sound, keyboardMap} = theme
    const {title, difficulty} = gameSettings
    const [score, setScore] = useState(0)
    const [target, setTarget] = useState(() => randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty}))
    const [solution, setSolution] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [showSolutionText, setShowSolutionText] = useState(false)
    const [scoreSound] = useSound(ScoreSound,{soundEnabled: sound})
    const {
        state: {numTileInfo, equationInfo, ans, usedOp, numTileString, numTileClicked, opTileClicked},
        NEW_TILES, RESET_TILES, UNDO_TILES, UPDATE_EQUATION
    } = useNumtiles()

    function showSolution() {
        const temp = getSolution({difficulty, target, numTiles: numTileString})
        if (solution) {
            setSolution("")
        } else {
            setSolution(temp)
        }
    }

    useEffect(() => {
        NEW_TILES({difficulty, target})
        return () => {}
    }, [])

    useEffect(() => { 
        if (ans === target) {
            const increaseScore = checkAns({difficulty, numTileInfo, opUsed: usedOp})
            if (increaseScore) {
                scoreSound()
                setScore(prevState => prevState + 1)
                setShowSolutionText(false)
                setSolution("")
                const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
                setTarget(tempTarget)
                NEW_TILES({difficulty, target: tempTarget})
            } else {
                // add something saying, you must use all of the tiles! or something like that 
            }
        }    
        return () => {}
    }, [ans])

    useEffect(() => { 
        let timerId;
        timerId = setInterval(() => {
            tempTime -= .5
            if (tempTime <= 0) {
                clearInterval(timerId)
                setDisabled(false)
            }
        }, 500);
        return () => {clearInterval(timerId)}
    }, [])

    useEffect(() => { 
        let solutionTextTimerId;
        solutionTextTimerId = setInterval(() => {
            showSolutionTextTime -= 1
            if (showSolutionTextTime <= 0) {
                clearInterval(solutionTextTimerId)
                setShowSolutionText(true)
            }
        }, 1000);
        return () => {clearInterval(solutionTextTimerId)}
    }, [score])

    return (
        <Page>
            <TopBar title={title} navigateTo={"/singleplayer"} isMobile={isMobile}/>
            <TargetContainer $flex={1.25}>
                <Target $size={"70px"} target={target}/>
            </TargetContainer>
            <Container $flex={1}>
                <Phrases $size={"20px"} score={score} phrases={inGamePhrases}/>
            </Container>
            <Container $flex={6} style={{alignItems: "flex-start"}}>
                <Gameboard 
                    equationInfo={equationInfo} numTileInfo={numTileInfo} 
                    numTileClicked={numTileClicked} opTileClicked={opTileClicked}
                    opUsed={usedOp} UNDO_TILES={UNDO_TILES}
                    UPDATE_EQUATION={UPDATE_EQUATION} RESET_TILES={RESET_TILES}
                    disabled={disabled} keyboardMap={keyboardMap}
                    isMobile={isMobile}
                />
            </Container>
            <Container $flex={1} $flexDirection={"column"} style={{justifyContent: "flex-start"}}>
                <Heading style={{cursor: "pointer", display: showSolutionText ? "block" : "none"}} onClick={showSolution}>Click here to show a solution</Heading>
                <Heading style={{cursor: "pointer", display: solution ? "block" : "none"}}>{solution}</Heading>
            </Container>
        </Page>
    )
}

export default Practice;