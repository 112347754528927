import { Container, Heading } from "../../global";
import { PressureDot } from "./styles";

const PressureDots = ({color1, color2, score1, score2, scoreToWin, title}) => {
    const pointsToWin = parseInt(scoreToWin)
    const scoreDots = []
    for (let i = 0; i < pointsToWin; i++) {
        if (score1 > i) {
            scoreDots.push(
                <PressureDot color={color1.card} key={i} $scoreToWin={pointsToWin}/>
            )
        } else if (i+score2 < pointsToWin) {
            scoreDots.push(
                <PressureDot key={i} $scoreToWin={pointsToWin}/>
            )
        } else {
            scoreDots.push(
                <PressureDot color={color2.card} key={i} $scoreToWin={pointsToWin}/>
            )
        }
    }
    return (
        <Container $flex={1}>
            <Container style={{transform: "rotate(270deg)"}}>
                <Heading $size={"10px"} style={{textWrap: "nowrap"}}>
                    {title}
                </Heading>
            </Container>
            <Container $flex={1} $gap={"4px"}>
                {scoreDots}
            </Container>
            <Container style={{transform: "rotate(90deg)"}}>
                <Heading $size={"10px"} style={{textWrap: "nowrap"}}>
                    {title}
                </Heading>
            </Container>
        </Container>
    )
}

export default PressureDots;