import { Container, Heading, LineSpace } from "../../global";
import { EndButtons, EndContainer } from "./styles";

const EndModal = ({difficulty, handlePlayAgain, handleQuit, score, newHs}) => {
    let congrats;
    if (score < 3) {
        congrats = "Nice! Your score is "
    } else if (score < 5) {
        congrats = "Wow! Your score is "
    } else if (score < 7) {
        congrats = "Impressive! Your score is "
    } else if (score < 10) {
        congrats = "Awesome! Your score is "
    } else if (score < 15) {
        congrats = "Super! Your score is "
    } else if (score < 20) {
        congrats = "Gee whiz! Your score is "
    } else if (score < 25) {
        congrats = "Unbelievable! Your score is "
    } else if (score >= 25) {
        congrats = "Impossible!! Your score is "
    } else {
        congrats = "Nice! Your score is "
    }

    return (
        <EndContainer $flexDirection={"column"} $justify={"flex-start"}>
            <Container $flex={1} $flexDirection={"column"} $justify={"space-evenly"}>
                <Heading $size={"24px"}>{difficulty}</Heading>
                <LineSpace/>
                <Heading>Time's up!</Heading>
                {newHs && <Heading>A new highscore!</Heading>}
                <Heading>{congrats}{score}.</Heading>
            </Container>
            <Container $flex={2} $justify={"space-evenly"}>
                <EndButtons onClick={handlePlayAgain}>Play Again</EndButtons>
                <EndButtons onClick={handleQuit}>Quit</EndButtons>
            </Container>
        </EndContainer>
    )
}

export {EndModal};