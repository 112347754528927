import { useContext, useEffect, useState } from "react";
import BotBar from "../../components/botbar/BotBar"
import { Container, Heading } from "../../global/styles/styles";
import DarkMode from "./DarkMode/DarkMode";
import { ThemeContext } from "../../global/contexts/themecontext/ThemeContext";
import { colors } from "../../global/styles/base";
import ThemeSwitch from "./ThemeSwitch/ThemeSwitch";
import { ContainerThemeButtons, ThemePage } from "./styles";
import Sound from "./Sound/Sound";
import KeyboardMap from "./KeyboardMap/KeyboardMap";

const SettingsPage = ({isMobile}) => {
    const themeContext = useContext(ThemeContext)
    const {theme, myTheme, setTheme} = themeContext
    const [color1, setColor1] = useState()
    const [color2, setColor2] = useState(myTheme.colors.background)
    const dark = [
        {
            $color: colors.DARK,
            value: true,
        },
        {
            $color: colors.LIGHT,
            value: false,
        },
    ]
    const themes = [
        {
            $color: colors.LIGHT_RED,
            value: "red",
        },
        {
            $color: colors.LIGHT_GREEN,
            value: "gree",
        },
        {
            $color: colors.LIGHT_BLUE,
            value: "blue",
        },
        {
            $color: colors.LIGHT_PURPLE,
            value: "purp",
        },
        {
            $color: colors.LIGHT_GRAY,
            value: "gray",
        },
    ]

    useEffect(() => {
        setColor2(prevState => {
            setColor1(prevState)
            return myTheme.colors.background 
        })
        return () => {}
    }, [themeContext])

    const darkButtons = dark.map(button => <DarkMode key={`dark-${button.value}`} theme={theme} setTheme={setTheme} {...button}/>) 
    const themeButtons = themes.map(button => <ThemeSwitch key={`theme-${button.value}`} theme={theme} setTheme={setTheme} {...button}/>) 

    return (
        <ThemePage $color1={color1} $color2={color2}>
            <Container $flex={10} $flexDirection={"column"} $gap={"5px"}>
            <Container $flexDirection={"column"} $gap={"3px"}>
                <Heading $size={"16px"}>Theme</Heading>
                <ContainerThemeButtons $flexDirection={"row"}>
                    {themeButtons}
                </ContainerThemeButtons>
            </Container>
            <Container $flexDirection={"column"} $gap={"3px"}>
                <Heading $size={"16px"}>Dark Mode</Heading>
                <ContainerThemeButtons $flexDirection={"row"}>
                    {darkButtons}
                </ContainerThemeButtons>
            </Container>
                <Container>
                    <Sound theme={theme} setTheme={setTheme} myTheme={myTheme}/>
                </Container>
                { !isMobile &&
                    <KeyboardMap theme={theme} setTheme={setTheme}/>
                }
            </Container>
            <Container $flex={.5}>
                <Heading $size={"14px"}>© 2024 CompuTiles. All rights reserved.</Heading>
            </Container>
            <Container>
                <BotBar/>
            </Container>
        </ThemePage>
    )
}

export default SettingsPage;