import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from "react-share";
import { Button, Container, Heading, LineSpace, Modal } from "../../global"
import { IoClose } from "react-icons/io5";

const ShareModal = ({
    difficulty, 
    highscore, 
    mode, 
    modeString, 
    setShowShare,
    title, 
    userStats, 
}) => {
    const shareUrl = "https://www.computiles.com"
    return (
        <Modal $height={"50%"}>
            <Container $flex={1} $flexDirection={"column"} $gap={"15px"}>
                <Container $flex={1}>
                    <Heading>Share your stats!</Heading>
                </Container>
                <LineSpace/>
                <Container $flex={6} $flexDirection={"column"} $gap={"10px"}>
                    <Heading>{modeString} - {title}</Heading>
                    <Heading>Highscore: {highscore || 0}</Heading>
                    <Heading>Rank: {userStats[mode][difficulty]["rank"] || 0} (Top {userStats[mode][difficulty]["percentile"] || 0}%)</Heading>
                    <Container $gap={"5px"}>
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl}>
                            <XIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <RedditShareButton url={shareUrl}>
                            <RedditIcon size={32} round />
                        </RedditShareButton>
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <EmailShareButton url={shareUrl}>
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                    </Container>
                </Container>
                <Container $flex={1}>
                    <Button onClick={() => setShowShare(false)}>
                        <Container $gap={"5px"}>
                            Cancel
                            <IoClose/>
                        </Container>
                    </Button>


                </Container>
            </Container>
        </Modal>
    )
}

export default ShareModal;