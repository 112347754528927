import { IoArrowUndo } from "react-icons/io5";
import { OtherTileButton } from "./styles";

const Undo = ({
    disabled,
    handleClick,
    isMobile,
    $multi
}) => {
    return(
        <OtherTileButton
            $multi={$multi}
            disabled={disabled}
            onClick={isMobile ? undefined : handleClick}
            onTouchStart={isMobile && !disabled ? handleClick : undefined}
        >
            <IoArrowUndo size={20} style={{minWidth: 15, marginRight: 3, marginBottom: 3}}/> UNDO
        </OtherTileButton>
    )
}

export default Undo;