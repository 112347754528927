import { useNavigate } from "react-router-dom";
import BotBar from "../../components/botbar/BotBar"
import { ContainerMenuButton, MenuButton, Title } from "./styles";
import { Container, Heading, Page } from "../../global/styles/styles";
import { HowToPlay } from "../../components/howtoplaymodal/HowToPlay";
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../global";
import { IoInformationCircleOutline } from "react-icons/io5";

const HomePage = ({isMobile}) => { 
    const navigate = useNavigate()
    const {theme, setTheme} = useContext(ThemeContext)
    const [multiButtonText, setMultiButtonText] = useState(false)
    const botbar = useRef(null)
    useEffect(() => {
        botbar.current.scrollIntoView()
        return () => {}
    },[])
    function handleClick(e) {
        navigate(e.target.value)
    }

    function saveTheme() {
        let tempTheme = theme
        tempTheme.tutorial = !theme.tutorial
        localStorage.setItem("Theme", JSON.stringify(tempTheme))
    }

    function handleClickHowToPlay() {
        setTheme(prevState => ({...prevState, tutorial: true}))
        saveTheme()
    }

    return (
        <Page>
            { theme.tutorial && <HowToPlay theme={theme} setTheme={setTheme}/> }
            <Container $flex={4} $flexDirection={"column"}>
                <Container $flexDirection={"row"}>
                    <Title>CompuTiles</Title>
                </Container>
                <Heading $size={"16px"}>Practice your math skills!</Heading>
            </Container>
            <ContainerMenuButton $flex={4} $flexDirection={"column"}>
                <MenuButton value={"/singleplayer"} onClick={handleClick}>
                    Single Player
                </MenuButton>
                {isMobile ?
                 <MenuButton value={"/multiplayer"} onClick={handleClick}>
                    Multiplayer
                </MenuButton> 
                : 
                <MenuButton onMouseEnter={() => setMultiButtonText(true)} onMouseLeave={() => setMultiButtonText(false)} value={"/multiplayer"} onClick={handleClick}>
                    {!multiButtonText ? 
                        <>Multiplayer</>
                        :
                        <>(table or phone recommended)</>
                    }
                </MenuButton>
                }
                <MenuButton onClick={handleClickHowToPlay}>
                    <Container $gap={"5px"}>
                        How to Play 
                        <IoInformationCircleOutline/>
                    </Container>
                </MenuButton>
            </ContainerMenuButton>
            <Container ref={botbar} $flex={3}>
                <BotBar/>
            </Container>
        </Page>
    )
}

export default HomePage;