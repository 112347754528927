import { styled } from "styled-components";
import { Heading } from "../../global";

const ContainerMidBar = styled.div`
    display: flex;
    height: 3vh;
    width: 100%;
    align-self: flex-start;
    align-items: center;
    justify-content: space-between;
`;

const TextMidBar = styled(Heading)`
    color: ${props => props.theme.title}
    font-size: ${props => typeof props.$size === "string" ? props.$size : "18px"};
`;

export {
    ContainerMidBar,
    TextMidBar,
};