import { Container, Heading } from "../../global/styles/styles";
import { GameSettingsButton, SettingButtonsContainer, SettingsContainer } from "./styles";

const GameSetting = ({setGameSettings, settings, $active}) => {
    function handleClick(e) {
        setGameSettings(prevState => ({...prevState, [settings.setting]: e.target.value}))
    }

    const settingButtons = settings.buttons.map(button => {
        return (
            <GameSettingsButton
                key={button.value}
                link={button.link}
                value={button.value}
                onClick={handleClick} 
                $active={$active === button.value.toString() ? true : false}
            >
                {button.text}
            </GameSettingsButton>
        )
    })

    return (
        <SettingsContainer $flexDirection={"column"}>
            <Container $flex={1}>
                <Heading>{settings.title}</Heading>
            </Container>
            <SettingButtonsContainer $flex={1}>
                {settingButtons}
            </SettingButtonsContainer>
        </SettingsContainer>
    )
}

export default GameSetting;