import React from "react";
import { IoVolumeHigh, IoVolumeOff } from "react-icons/io5";
import { Container, Heading } from "../../../global";

const Sound = ({theme, setTheme, myTheme}) => {
    function handleClickSound() {
        setTheme(prevState => ({...prevState, sound: !theme.sound}))
        saveTheme()
    }
    function saveTheme() {
        let tempTheme = theme
        tempTheme.sound = !theme.sound
        localStorage.setItem("Theme", JSON.stringify(tempTheme))
    }

    let active = false;
    if (theme.sound) {
        active = true; 
    }

    return active ? 
        <Container onClick={handleClickSound} $flexDirection={"column"} style={{cursor: "pointer", width: "min-content", textWrap: "nowrap"}}>
            <Heading $size={"16px"}>Sound On</Heading>
            <IoVolumeHigh size={"3em"} color={myTheme.colors.title}/> 
        </Container>
        : 
        <Container onClick={handleClickSound} $flexDirection={"column"} style={{cursor: "pointer", width: "min-content", textWrap: "nowrap"}}>
            <Heading $size={"16px"}>Sound Off</Heading>
            <IoVolumeOff size={"3em"} color={myTheme.colors.title}/>
        </Container>
}

export default Sound;