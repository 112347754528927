import styled from "styled-components";
import { Heading } from "../../global";

const HelperText = styled(Heading)`
    font-style: italic;
    font-size: 16px;
`;

export {
    HelperText
};