import { Heading, Container, LineSpace } from "../../global";
import Description from "../description/Description";
import Phrases from "../phrases/Phrases";
import { loadingPhrases } from "../phrases/utils";
import TopBar from "../topbar/TopBar";
import { Blink, LoadingPage, } from "./styles";

const loading = ({gameSettings}) => {
    return (
        <LoadingPage>
            <TopBar title={gameSettings.title} navigateTo={"/singleplayer"}/>
            <Container $flex={.5} $flexDirection={"column"}>
                <Heading $size={"26px"}>{gameSettings.mode === "p" ? "Practice" : "Time Trial"}</Heading> 
            </Container>
            <LineSpace/>
            <Container $flex={2} $flexDirection={"column"}>
                <Description gameSettings={gameSettings}/>
            </Container>
            <Container $flex={1} $flexDirection={"column"}>
                <Phrases phrases={loadingPhrases} trigger/>
            </Container>
            <Container $flex={2}>
                <Heading $size={"20px"}>Loading</Heading>
                <Blink $size={"20px"} $delay={0}>.</Blink>
                <Blink $size={"20px"} $delay={0.25}>.</Blink>
                <Blink $size={"20px"} $delay={0.5}>.</Blink>
            </Container>
        </LoadingPage>
    )
}

const loaded = ({onClick, gameSettings}) => {
    return (
        <LoadingPage>
            <TopBar title={gameSettings.title} navigateTo={"/singleplayer"}/>
            <Container $flex={.5} $flexDirection={"column"}>
                <Heading $size={"26px"}>{gameSettings.mode === "p" ? "Practice" : "Time Trial"}</Heading> 
            </Container>
            <LineSpace/>
            <Container $flex={2} onClick={onClick} $flexDirection={"column"}>
                <Description gameSettings={gameSettings}/>
            </Container>
            <Container $flex={1} onClick={onClick}>
                <Phrases phrases={loadingPhrases} trigger/>
            </Container>
            <Container $flex={2} onClick={onClick} $flexDirection={"column"}>
                <Heading $size={"20px"}>Ready!</Heading>
                <Blink $size={"20px"}>Click or tap to continue...</Blink>
            </Container>
        </LoadingPage>
    )
}

const LoadingGame = ({dataReady, onClick, gameSettings}) => {
    return dataReady ? loaded({onClick, gameSettings}) : loading({gameSettings})
}

export default LoadingGame;