import { styled } from "styled-components";
import { colors } from "../../../global/styles/base";
import { Container } from "../../../global/styles/styles";
import { GameboardButton } from "../styles";

const OpTileButton = styled(GameboardButton)`
    font-size: 24px;
    min-height: ${props => props.$multi === true ? "30px" : "50px"};
    max-height: 55px;
    height: 10vw;
    min-width: 55px;
    max-width: 80px;
    width: 18vw;
`;

const OpIndicator = styled.div`
    background-color: ${props => props.$used > 0 ? props.$overFour >= 4 ? colors.HAPPY_GREEN : props.theme.title : null};
    width: 7px;
    height: 7px;
    border-color: ${props => props.theme.background};
    border-radius: 7px;
    border-style: solid;
    border-width: 1px;
`;

const OpContainer = styled(Container)`
    gap: ${props => props.$multi ? "0.5vh" : "7px"};
    flex-direction: column;
`;

export {
    OpContainer,
    OpIndicator,
    OpTileButton,
};