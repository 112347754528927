import { accessControlAllowOrigin, envUrls } from "../../global"

const refreshToken = async (user) => {
    try {
        const response = await fetch(envUrls.refreshToken, {
            method: "POST",
            headers: {
            "Content-Type": "Application/json",
            "Access-Control-Allow-Origin": accessControlAllowOrigin
            },
            credentials: "include",
            body: JSON.stringify({_id: user._id})
            })
        const refreshData = await response.json()
        const refreshStatus = response.status
        if (refreshStatus === 403) {
            console.warn(refreshData)
        } else {
            console.log(refreshData)
        }
        return {refreshStatus, refreshData}
    } catch (err) {
        console.warn("Something's wrong")
    }
}

export { refreshToken };