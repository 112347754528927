import { ContainerNumTiles } from "../../../components/gameboard/styles";
import { Container } from "../../../global";
import { SettingGameboard } from "./styles";
import { ToggleBoardButton } from "../styles";

const KeyboardGameboard = ({
    numTiles,
    opTiles,
    otherTiles,
    onSave, 
    onDefault
}) => { 
    return (
        <SettingGameboard
            $gap={"5px"}
            $flexDirection={"column"}
        >
            <ContainerNumTiles>
                {numTiles}
            </ContainerNumTiles>
            <Container $gap={"3px"}>
                {opTiles}
            </Container>
            <Container $gap={"4px"}>
                {otherTiles}
            </Container>
            <Container $gap={"20px"}>
                <ToggleBoardButton onClick={onSave}>Save</ToggleBoardButton>
                <ToggleBoardButton onClick={onDefault}>Default</ToggleBoardButton>
            </Container>
        </SettingGameboard>
    )
}

export default KeyboardGameboard;