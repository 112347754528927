import { styled } from "styled-components";
import { GameboardButton } from "../styles";

const NumTileButton = styled(GameboardButton)`
    cursor: ${props => props.$invisible === true ? "default" : "pointer"};
    font-size: 32px;
    min-height: ${props => props.$multi === true ? "55px" : "90px"};
    max-height: 125px;
    height: ${props => props.$multi === true ? "8vh" : "30vw"};
    min-width: ${props => props.$multi === true ? "80px" : "90px"};
    max-width: 125px;
    width: ${props => props.$multi === true ? "30vw" : "30vw"};
    opacity: ${props => props.$invisible === true ? 0 : 1};
`;

export {
    NumTileButton,
};