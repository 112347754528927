import { createContext, useEffect, useState } from "react";

export const UserContext = createContext()

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("SignedInUser")) 
    || JSON.parse(localStorage.getItem("DefaultUser"))
    || {highscores: { 
          timeTrial: {
            hse60: 0,
            hsm90: 0,
            hsh120: 0,
          }
        }}
  )
  const [postNewHs, setPostNewHs] = useState(false)

  useEffect(() => {
    if (postNewHs) {
      if (user.username) {
        localStorage.setItem("SignedInUser", JSON.stringify(user)) 
      } else {
        localStorage.setItem("DefaultUser", JSON.stringify(user)) 
      }
    }
    return () => { }
  }, [postNewHs]) // eslint-disable-line

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("SignedInUser")) || false;
    if (userInfo) {
      let tempUser = userInfo;
      tempUser.highscores = { 
        timeTrial: {
          hse60: userInfo.highscores.timeTrial.hse60 || 0,
          hsm90: userInfo.highscores.timeTrial.hsm90 || 0,
          hsh120: userInfo.highscores.timeTrial.hsh120 || 0,
        }
      }
      localStorage.setItem("SignedInUser", JSON.stringify(tempUser))
      userInfo = JSON.parse(localStorage.getItem("SignedInUser"))
      setUser(userInfo)
    } else {
      let defaultUser = JSON.parse(localStorage.getItem("DefaultUser")) || false;
      if (!defaultUser) {
        localStorage.setItem("DefaultUser", JSON.stringify({
          highscores: { 
            timeTrial: {
              hse60: 0,
              hsm90: 0,
              hsh120: 0,
            }
          }
        }))
      }
      defaultUser = JSON.parse(localStorage.getItem("DefaultUser"))
      setUser(defaultUser)
    }
    return () => { }
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser, setPostNewHs }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider;