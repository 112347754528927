import React, { useEffect, useState } from "react";
import { TimerBar } from "./styles";

const Timer = ({startTimer, time, setTimerFinished}) => {
    let tempTime = time
    const [timer, setTimer] = useState(tempTime)
    const [timerWidth, setTimerWidth] = useState(100)
    
    useEffect(() => {
        setTimerWidth((100*(timer/time).toFixed(4)))
        return () => {}
    }, [timer])

    useEffect(() => {
        if (startTimer) {
            let timerId
            timerId = setInterval(() => {
                tempTime--
                setTimer((prevState) => prevState-1)
                if (tempTime <= 0) {
                    setTimer(time)
                    tempTime = time
                    clearInterval(timerId)
                    setTimerFinished(true)
                }
            }, 1000);
            return () => {clearInterval(timerId)}
        } 
    }, [startTimer])
    
    return (
        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
            <TimerBar $timer={timer} style={{width: `${timerWidth}%`}}/>
        </div>
    )
}

export default Timer;