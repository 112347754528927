import { useState } from "react";
import BotBar from "../../components/botbar/BotBar";
import { ClickableText, Container, Heading, Input, Page, accessControlAllowOrigin, envUrls } from "../../global";
import { FormContainer, InputContainer, ProfileButton, TextError } from "./styles";

const Registration = ({setShowSignUp}) => {
    const [userCreated, setUserCreated] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    function handleClickSignUp() {
        setShowSignUp(false)
    }

    const signUp = async (e) => {
        e.preventDefault()
        try {
            if (password === password2 && password.length > 0) {
                const response = await fetch(envUrls.registerUser, {
                    method: "POST",
                    headers: {
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Origin": accessControlAllowOrigin
                    },
                    body: JSON.stringify({ email, username, password })
                })
                const data = await response.json()
                const status = response.status
                if (status === 400) {
                    setErrorMessage(data)
                } else {
                    setUserCreated("User Created. Please sign in")
                    setErrorMessage("")
                }
            }
        } catch(err) {
            console.warn("Something's wrong")
        }
    }
    
    return (
        <Page>
            <FormContainer onSubmit={signUp}>
                {userCreated && <TextError $warn={false} $size={"16px"}>{userCreated}</TextError>}
                <Heading $size={"25px"}>Sign Up</Heading>
                {errorMessage && <TextError $warn={true} $size={"16px"}>{errorMessage}</TextError>}
                <InputContainer>
                    <Heading>Email</Heading>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"email"}
                        type={"email"}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email} 
                    />
                </InputContainer>
                <InputContainer>
                    <Container $flexDirection={"column"}>
                        <Heading>Username</Heading>
                        <Heading $size={"16px"}>- Must be less than 20 characters</Heading> 
                    </Container>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setUsername(e.target.value)}
                        value={username} 
                    />
                </InputContainer>
                <InputContainer>
                    <Container $flexDirection={"column"}>
                        <Heading>Password</Heading> 
                        <Heading $size={"16px"}>- Must be at least 8 characters long</Heading> 
                    </Container>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setPassword(e.target.value)}
                        type={"password"}
                        value={password} 
                    />
                </InputContainer>
                <InputContainer>
                    <Heading>Re-type password</Heading>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setPassword2(e.target.value)}
                        type={"password"}
                        value={password2} 
                    />
                </InputContainer>
                {password === password2 ? 
                    password.length > 0 && <TextError $warn={false} $size={"16px"}>Passwords match!</TextError> 
                    : 
                    <TextError $warn={true} $size={"16px"}>Passwords don't match!</TextError>
                }
                <ProfileButton type="submit" onClick={signUp}>Sign Up</ProfileButton>
                <Container $flexDirection={"row"} $gap={"7px"}>
                    <Heading $size={"16px"}>Existing user?</Heading>
                    <ClickableText $size={"16px"} onClick={handleClickSignUp}>Sign In</ClickableText>
                </Container>
            </FormContainer>
            <Container>
                <BotBar/>
            </Container>
        </Page>
    )
}

export default Registration;