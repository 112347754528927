import React from "react";
import { ItemBotBar } from "./styles";
import { useLocation } from "react-router-dom";

const BotBarItem = ({icon, label, link}) => {
    const location = useLocation()
    let active = false;
    if (location.pathname === link) {
        active = true; 
    }
    return (
        <ItemBotBar $active={active} to={link} >
            {icon}
            <p>
                {label}
            </p>
        </ItemBotBar>
    )
}

export default BotBarItem;