import { Container, Heading, LineSpace } from "../../global";
import { EndButtons, EndContainer } from "./styles";

const MultiplayerEndModal = ({handlePlayAgain, handleQuit, score1, score2, winner, pressure}) => {
    return (
        <EndContainer $flexDirection={"column"} $justify={"flex-start"}>
            <Container $flex={1} $flexDirection={"column"} $justify={"space-evenly"}>
                <Heading $size={"24px"}>{winner}</Heading>
                <LineSpace/>
                {!pressure && 
                    <>
                        <Heading>Score</Heading>
                        <Heading>{score1}-{score2}</Heading>
                    </>
                }
            </Container>
            <Container $flex={2} $justify={"space-evenly"}>
                <EndButtons onClick={handlePlayAgain}>Play Again</EndButtons>
                <EndButtons onClick={handleQuit}>Quit</EndButtons>
            </Container>
        </EndContainer>
    )
}

export {MultiplayerEndModal};