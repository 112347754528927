import { Heading } from "../../global";
import { MessageModalContainer } from "./styles";

const MessageModal = ({children, $size}) => {
    return (
        <MessageModalContainer>
            <Heading $size={$size}>{children}</Heading>
        </MessageModalContainer>
    )
}

export default MessageModal;