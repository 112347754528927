import { accessControlAllowOrigin, envUrls } from "../../global"
import { refreshToken } from "../profile/utils"
import { useReducer } from "react";
import { numTileReroll, opTileClean } from "../../global";

function tileChange({ans, equationInfo, numTileInfo}) {
    const tempNumsInfo = JSON.parse(JSON.stringify(numTileInfo))
    equationInfo.filter(a => isFinite(a.value)).forEach((a, index) => {
        const foundTile = tempNumsInfo.find(b => b.id === a.id)
        if (index === 0) {
            foundTile.value = ans
        } else {
            foundTile.value = "empty"
            foundTile.$invisible = true
        }
    })
    return tempNumsInfo
}

function checkAns({difficulty, numTileInfo, opUsed}) {
    // const tempOpUsed = [...opUsed]
    if (difficulty === "x") {
        if (numTileInfo.filter(numTile => numTile.value === "empty").length >= 5) {
            return true
        }
    // } 
    // else if (difficulty === "x") {
    //     if (tempOpUsed.filter(a => a>0).length >= 4 && numTileInfo.filter(numTile => numTile.value === "empty").length >= 5)  {
    //         return true
    //     }
    } else if (difficulty === "e" || "m" || "h") {
        return true
    }
    return false
}

async function postToDB({score, user, gameString, mode}) {
    try {
        const {refreshData, refreshStatus} = await refreshToken(user) 
        if (refreshStatus === 200) {
            const response = await fetch(envUrls.updateHs, {
                method: "PUT",
                headers: {
                "Content-Type": "Application/json",
                "Access-Control-Allow-Origin": accessControlAllowOrigin
                },
                credentials: "include",
                body: JSON.stringify({
                    "_id": user._id,
                    "gameSetting": gameString, 
                    "mode": mode, 
                    "value": score,
                })
            })
            const data = await response.json()
            const status = response.status
            if (status === 401) {
                console.log("401", data)
            } else {
                console.log("200", data)
            }
        } else {
            console.log(refreshData)
        }
    } catch (error) {
        console.log("Something went wrong. Highscore not saved")
    }
}

function useNumtiles() {
    const [state, dispatch] = useReducer((state, action) => {
        const {
            equationInfo,
            numTileClicked,
            numTileInfo,
            opTileClicked,
            undoArray,
            usedOp
        } = state
        const {difficulty, target, tile, type} = action
        switch (type) {
            case "NEW_TILES":
                const {numTiles, numString} = numTileReroll({difficulty, target})
                return {
                    ...state,
                    numTileInfo: [...numTiles],
                    numTileString: numString,
                    undoArray: [{numTiles, usedOp: [0,0,0,0,0]}],
                    equationInfo: [],
                    usedOp: [0,0,0,0,0],
                    numTileClicked: [false, false, false, false, false, false],
                    opTileClicked: [false, false, false, false, false],
                    ans: 0
                }         
            case "RESET_TILES":
                return {
                    ...state,
                    numTileInfo: undoArray[0].numTiles,
                    undoArray: [undoArray[0]],
                    equationInfo: [],
                    usedOp: [0, 0, 0, 0, 0],
                    numTileClicked: [false, false, false, false, false, false],
                    opTileClicked: [false, false, false, false, false],    
                    ans: 0
                };         
            case "UNDO_TILES":
                return undoTiles(undoArray, state)
            case "UPDATE_EQUATION":
                // Add tile to equation and set clicked
                const { id, value } = tile
                let tempEqInfo = [...equationInfo]
                const tempNumTileClicked = [...numTileClicked]
                let tempOpTileClicked = [...opTileClicked]
                if (Object.hasOwn(tile, "id")) {
                    // If numtile
                    if (numTileClicked[id]) {
                        // If the tile is already clicked, remove the value from equation info
                        const dupe = tempEqInfo.findIndex(eq => eq.id === id)
                        tempEqInfo.splice(dupe, 1)
                    } else {
                        tempEqInfo = [...equationInfo, {id, value}]
                    }
                    tempNumTileClicked[id] = !numTileClicked[id]
                } else {
                    // If optile
                    const opTile = opTileClean[tile]
                    const {symbol} = opTile
                    const opIndex = Object.values(opTileClean).indexOf(opTile)
                    if (opTileClicked[opIndex]) {
                        tempOpTileClicked[opIndex] = !tempOpTileClicked[opIndex]
                        tempEqInfo.splice(tempEqInfo.findIndex(a => a.symbol === symbol),1)
                    } else {
                        tempOpTileClicked = [false,false,false,false,false]
                        tempOpTileClicked[opIndex] = true
                        //If the equation has another symbol, return the index of the symbol and then splice with new symbol
                        if (tempEqInfo.find((a) => a.symbol)) {
                            tempEqInfo.splice(tempEqInfo.findIndex(a => {
                                if (a.symbol) {
                                    return a.symbol !== symbol
                                } 
                            }), 1, {symbol})
                        } else {
                            tempEqInfo = [...equationInfo, {symbol}]
                        }
                    }
                }                
                // if equation.length is > 3 and contains an operation, then solve
                const equationString = tempEqInfo.map(a => a.symbol ? a.symbol : a.value)
                if (equationString.length >= 3 && (equationString.includes("+") || equationString.includes("-") || equationString.includes("÷") || equationString.includes("x") || equationString.includes("^"))) {
                    let tempAns
                    let tempUsedOp = [...usedOp]
                    let tempEq = [...equationString]
                    if (equationString.join("").match(/[\^]/)) {
                        tempEq.splice(tempEq.indexOf("^"), 1)
                        tempAns = tempEq.reduce((accu, curr) => accu ** curr)
                        tempUsedOp[4] += 1
                    } else if (equationString.join("").match(/[x]/)) {
                        tempEq.splice(tempEq.indexOf("x"), 1)
                        tempAns = tempEq.reduce((accu, curr) => accu * curr)
                        tempUsedOp[2] += 1
                    } else if (equationString.join("").match(/[÷]/)) {
                        tempEq.splice(tempEq.indexOf("÷"), 1)
                        tempAns = tempEq.reduce((accu, curr) => accu / curr)
                        tempUsedOp[3] += 1
                    } else if (equationString.join("").match(/[+]/)) {
                        tempEq.splice(tempEq.indexOf("+"), 1)
                        tempAns = tempEq.reduce((accu, curr) => parseFloat(accu) + parseFloat(curr))
                        tempUsedOp[0] += 1
                    } else if (equationString.join("").match(/[-]/)) {
                        tempEq.splice(tempEq.indexOf("-"), 1)
                        tempAns = tempEq.reduce((accu, curr) => accu - curr)
                        tempUsedOp[1] += 1
                    } 
                    if (isNaN(tempAns) || tempAns === Infinity || tempAns === -Infinity) {
                        return undoTiles(undoArray, state)
                    } else {
                        if (tempAns%1 !== 0) {
                            tempAns = parseFloat(tempAns).toFixed(3)
                        }
                        const tempNumtileInfo = tileChange({
                            ans: tempAns,
                            equationInfo: tempEqInfo,
                            numTileInfo
                        })
                        const deepCopyUndo = [...JSON.parse(JSON.stringify(undoArray)), {numTiles: tempNumtileInfo, usedOp: tempUsedOp}]
                        return {
                            ...state,
                            ans: tempAns,
                            numTileInfo: tempNumtileInfo,
                            undoArray: deepCopyUndo,
                            equationInfo: [],
                            usedOp: tempUsedOp,
                            numTileClicked: [false, false, false, false, false, false],
                            opTileClicked: [false, false, false, false, false],        
                        }
                    }  
                }
                return {
                    ...state,
                    equationInfo: tempEqInfo,
                    numTileClicked: tempNumTileClicked,
                    opTileClicked: tempOpTileClicked,
                }
        }
    }, {
        ans: 0,
        equationInfo: [],
        numTileInfo: [],
        numTileString: "",
        undoArray: [],
        usedOp: [0,0,0,0,0],
        numTileClicked: [false, false, false, false, false, false],
        opTileClicked: [false, false, false, false, false],
    })
    const actions = {
        UPDATE_EQUATION: ({tile}) => dispatch({type: "UPDATE_EQUATION", tile}),
        NEW_TILES: ({difficulty, target}) => dispatch({type: "NEW_TILES", difficulty, target}),
        RESET_TILES: () => dispatch({type: "RESET_TILES"}),
        UNDO_TILES: () => dispatch({type: "UNDO_TILES"})
    }
    return {state, ...actions}
}

function undoTiles(undoArray, state) {
    const undoLength = undoArray.length;
    if (undoLength <= 2) {
        return {
            ...state,
            numTileInfo: undoArray[0].numTiles,
            undoArray: [undoArray[0]],
            equationInfo: [],
            usedOp: [0, 0, 0, 0, 0],
            numTileClicked: [false, false, false, false, false, false],
            opTileClicked: [false, false, false, false, false],    
            ans: 0
        };
    } else {
        const tempUndoArray = [...undoArray];
        tempUndoArray.pop();
        return {
            ...state,
            numTileInfo: tempUndoArray[tempUndoArray.length - 1].numTiles,
            undoArray: tempUndoArray,
            usedOp: tempUndoArray[tempUndoArray.length - 1].usedOp,
            equationInfo: [],
            numTileClicked: [false, false, false, false, false, false],
            opTileClicked: [false, false, false, false, false],    
            ans: 0
        };
    }
}

export {
    checkAns,
    postToDB,
    tileChange,
    useNumtiles
};