import { useState } from "react";
import KeyboardGameboard from "./KeyboardGameboard";
import { KeyChangeNumTile, KeyChangeOpTile, KeyChangeOtherTile, SpecialInput } from "./styles";
import { Container, Heading, defaultKeyboardMap } from "../../../global";
import { ToggleBoardButton } from "../styles";

//put a copy of keyboardMap in localStorage theme object
//get a copy of the keyboard map in localStorage
//change the copy and then save it after all the keys are changed or when save is clicked

const symbols = ["+", "-", "x", "÷", "^"]
const others = ["Reroll", "Reset", "Undo"]

const KeyboardMap = ({theme, setTheme}) => {    
    const [toggleGameboard, setToggleGameboard] = useState(false)
    const [keyNumTile, setKeyNumTile] = useState(Object.keys(theme.keyboardMap.numTiles))
    const [keyOpTile, setKeyOpTile] = useState(Object.keys(theme.keyboardMap.opTiles))
    const [keyOtherTile, setKeyOtherTile] = useState(Object.keys(theme.keyboardMap.otherTiles))
    function handleSetNumTileKey({e, tile}) {
        const tempKeys = [...keyNumTile]
        tempKeys[tile.tile] = e.code
        setKeyNumTile(tempKeys)
    }

    function handleSetOpTileKey({e, index}) {
        const tempKeys = [...keyOpTile]
        tempKeys[index] = e.code
        setKeyOpTile(tempKeys)
    }

    function handleSetOtherTileKey({e, index}) {
        const tempKeys = [...keyOtherTile]
        tempKeys[index] = e.code
        setKeyOtherTile(tempKeys)
    }

    const numTileKeys = []
    for (let key in theme.keyboardMap.numTiles) {
        numTileKeys.push({key, tile: theme.keyboardMap.numTiles[key]})
    }
    const opTileKeys = []
    for (let key in theme.keyboardMap.opTiles) {
        opTileKeys.push({key, tile: theme.keyboardMap.opTiles[key]})
    }
    const otherTileKeys = []
    for (let key in theme.keyboardMap.otherTiles) {
        otherTileKeys.push({key, tile: theme.keyboardMap.otherTiles[key]})
    }

    function onSave() {
        setTheme(prevState => {
            const tempTheme = {...prevState, 
                keyboardMap: {
                    numTiles: {
                        [keyNumTile[0]]: 0,
                        [keyNumTile[1]]: 1,
                        [keyNumTile[2]]: 2,
                        [keyNumTile[3]]: 3,
                        [keyNumTile[4]]: 4,
                        [keyNumTile[5]]: 5,
                    },
                    opTiles: {
                        [keyOpTile[0]]: "opTileAdd",
                        [keyOpTile[1]]: "opTileSub",
                        [keyOpTile[2]]: "opTileMul",
                        [keyOpTile[3]]: "opTileDiv",
                        [keyOpTile[4]]: "opTileExp",
            
                    },
                    otherTiles: {
                        [keyOtherTile[0]]: "rerollTile",
                        [keyOtherTile[1]]: "resetTile",
                        [keyOtherTile[2]]: "undoTile",
                    }
                }
            }
            localStorage.setItem("Theme", JSON.stringify(tempTheme))
            return tempTheme
        })
    }

    async function onDefault() {
        await setTheme(prevState => {
            localStorage.setItem("Theme", JSON.stringify({...prevState, keyboardMap: defaultKeyboardMap}))
            return {...prevState, keyboardMap: defaultKeyboardMap}
        })
    }

    const numTiles = numTileKeys.map((tile, index) => (
        <KeyChangeNumTile 
            key={index}
        >
            <Container $flexDirection={"column"}>
                <Container>
                    <SpecialInput 
                        placeholder={toggleGameboard ? `Numtile ${tile.tile}` : tile.key.replace("Key","")}
                        value={toggleGameboard ? `Numtile ${tile.tile}` : keyNumTile[index].replace("Key","")}
                        onKeyDown={(e) => handleSetNumTileKey({e, tile})}
                        onChange={()=>{}}
                    />
                </Container>
            </Container>
        </KeyChangeNumTile>
    ))

    const opTiles = opTileKeys.map((op, index) => (
        <KeyChangeOpTile
            key={index}
        >
            <Container $flexDirection={"column"}>
                <Container>
                    <SpecialInput 
                        placeholder={toggleGameboard ? `${symbols[index]}` : op.key.replace("Key","")}
                        value={toggleGameboard ? `${symbols[index]}` : keyOpTile[index].replace("Key","")}
                        onKeyDown={(e) => handleSetOpTileKey({e, index})}
                        onChange={()=>{}}
                    />
                </Container>
            </Container>
        </KeyChangeOpTile>
    ))

    const otherTiles = otherTileKeys.map((other, index) => (
        <KeyChangeOtherTile
            key={index}
        >
            <Container $flexDirection={"column"}>
                <Container>
                    <SpecialInput 
                        placeholder={toggleGameboard ? `${others[index]}` : other.key.replace("Key","")}
                        value={toggleGameboard ? `${others[index]}` : keyOtherTile[index].replace("Key","")}
                        onKeyDown={(e) => handleSetOtherTileKey({e, index})}
                        onChange={()=>{}}
                    />
                </Container>
            </Container>
        </KeyChangeOtherTile>
    ))

    return (
        <Container $flexDirection={"column"} $gap={"3px"}>
            <Container $gap={"5px"}>
                <Heading $size={"16px"}>Keyboard Map</Heading>
                <ToggleBoardButton onClick={() => setToggleGameboard(!toggleGameboard)}>Toggle Board</ToggleBoardButton>
            </Container>
            <KeyboardGameboard 
                numTiles={numTiles} opTiles={opTiles} otherTiles={otherTiles} 
                onDefault={onDefault} onSave={onSave}
            />
        </Container>
    )
}

export default KeyboardMap;