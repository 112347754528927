import styled from "styled-components";
import { Button, Modal } from "../../global";

const EndButtons = styled(Button)`
    width: 20%;
    min-width: 100px;
`;

const EndContainer = styled(Modal)`
    gap: 10px;
`;

export {
    EndButtons,
    EndContainer,
};