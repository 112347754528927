import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/Home";
import Leaderboard from "./pages/leaderboard/Leaderboard";
import ProfilePage from "./pages/profile/Profile";
import SettingsPage from "./pages/settings/Settings";
import AboutMe from "./pages/aboutme/AboutMe";
import Game from "./pages/game/Game";
import Multiplayer from "./pages/multiplayer/Multiplayer";
import SinglePlayer from "./pages/singleplayer/SinglePlayer";
import { ThemeProvider } from "styled-components";
import { useContext } from "react";
import { ThemeContext } from "./global/contexts/themecontext/ThemeContext";
import { Button, Container, Page, UserContext } from "./global";
import ChangePassword from "./pages/profile/ChangePassword";
import DeleteUser from "./pages/profile/DeleteUser";
import TestPage from "./pages/game/TestPage";
import { isMobile, isMobileSafari } from "react-device-detect";

function Main() {
    const theme = useContext(ThemeContext)
    const {user} = useContext(UserContext)

    return (
        <ThemeProvider theme={theme.myTheme.colors}>
            <div style={{backgroundColor: theme.myTheme.colors.background, display: "flex", justifyContent: "center"}}>
            <Routes>
                <Route path="/" element={<HomePage isMobile={isMobile} />} />
                {process.env.NODE_ENV === "development" && 
                    <Route path="/test" element={ <TestPage/>
                        // <Page>
                        //     <Container style={{height: "50%", backgroundColor: "white"}} >
                        //         <Container style={{position: "relative", height: "15%", backgroundColor: "red", zIndex: 3}} >
                        //         </Container>
                        //         <Container style={{height: "50%", backgroundColor: "pink", zIndex: 2}} >
                        //         </Container>
                        //     </Container>
                        //     <Container style={{height: "25%", backgroundColor: "yellow"}} >
                        //         <Button onClick={() => {
                        //             console.log(window.navigator.vibrate(0))
                        //             window.navigator.vibrate([200,100,200,100,200])
                        //             console.log("vibin")
                        //         }}>VIBRATE</Button>
                        //     </Container>
                        // </Page>
                    } />
                }
                <Route path="/singleplayer" element={<SinglePlayer isMobile={isMobile} isMobileSafari={isMobileSafari} />} />
                <Route path="/multiplayer" element={<Multiplayer isMobile={isMobile} isMobileSafari={isMobileSafari} />} />
                <Route path="/leaderboard" element={<Leaderboard />} />
                <Route path="/settings" element={<SettingsPage isMobile={isMobile} />} />
                {/* <Route path="/about-me" element={<AboutMe />} /> */}
                <Route path="/game" element={<Game isMobile={isMobile} />} />
                <Route path="/profile" element={<ProfilePage />} />
                {/* <Route path="/profile/forgot-password" element={<ForgotPassword />} /> */}
                <Route path="/profile/change-password" element={<ChangePassword />} />
                {user.username && 
                    <>
                        <Route path="/profile/delete-account" element={<DeleteUser />} />
                    </>
                }

            </Routes>
            </div>
        </ThemeProvider>
    );
}

export default Main;
