import { Container, Heading, LineSpace, ThemeContext } from "../../global";
import { ContentText, HeaderText, HowToPlayButton, HowToPlayButtonContainer, HowToPlayContainer, HowToPlayContent, HowToPlayGif, HowToPlayModal } from "./styles";
import gif1 from "../../assets/gifs/howToPlay1.gif";
import gif2 from "../../assets/gifs/howToPlay2.gif";
import { useContext } from "react";

const HowToPlay = ({ setTheme }) => {
    const {theme} = useContext(ThemeContext)

    function saveTheme() {
        let tempTheme = theme
        tempTheme.tutorial = !theme.tutorial
        localStorage.setItem("Theme", JSON.stringify(tempTheme))
    }

    function handleClickGotIt() {
        setTheme(prevState => ({ ...prevState, tutorial: false }))
        saveTheme()
    }

    return (
        <HowToPlayContainer>
            <HowToPlayModal $height={"95%"}>
                <HowToPlayContent>
                    <Container $flexDirection={"column"}>
                        <Heading $size={"20px"}>CompuTiles</Heading>
                        <LineSpace />
                    </Container>
                    <ContentText>
                        Goal: Get to the target value using basic math operations.
                    </ContentText>
                    <Container $flexDirection={"column"}>
                        <HeaderText>How to Play</HeaderText>
                        <LineSpace />
                    </Container>
                    <Container $flexDirection={"column"}>
                        <ContentText>
                            • You have 6 number tiles with random values from 1-9.
                        </ContentText>
                        <ContentText>
                            • Use the number tiles and operations to compute to the target value.
                        </ContentText>
                    </Container>
                    <HowToPlayGif src={gif1} alt="howtoplay1"/>
                    <Container $flexDirection={"column"}>
                        <ContentText>
                            • You can click multiple number tiles and then click an operation tile to combine the selected number tiles.
                        </ContentText>
                    </Container>
                    <HowToPlayGif src={gif2} alt="howtoplay2" />
                    {/* <Container $flexDirection={"column"}>
                        <HeaderText>Difficulty</HeaderText>
                        <LineSpace />
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Normal:</ContentText>
                        <ContentText>
                            Use any number of number tiles to compute to the target value.
                        </ContentText>
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Hard:</ContentText>
                        <ContentText>
                            Use ALL the number tiles to compute to the target value.
                        </ContentText>
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Expert:</ContentText>
                        <ContentText>
                            Use ALL the number tiles and at least 4 different operations to compute to the target value.
                        </ContentText>
                    </Container> */}
                    <Container $flexDirection={"column"}>
                        <HeaderText>Single Player</HeaderText>
                        <LineSpace />
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Practice: </ContentText>
                        <ContentText>
                            Compute to the target value with no timer.
                        </ContentText>
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Time Trial: </ContentText>
                        <ContentText>
                            Compute to the target value before time runs out. The timer changes with difficulty. Highscores will be saved!                        </ContentText>
                    </Container>
                    <Container $flexDirection={"column"}>
                        <HeaderText>Multiplayer</HeaderText>
                        <LineSpace />
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Buzzer: </ContentText>
                        <ContentText>
                            Find the solution the faster than your opponent.
                            Both players will get the same set of number tiles.
                            Tap 'Got it!' and input your answer before time runs out.
                            If you fail to get the answer within that time, you're not allowed to buzz in again unless your opponent fails.
                            Both players need to reroll to change the number tiles.
                        </ContentText>
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Time Attack: </ContentText>
                        <ContentText>
                            Solve more puzzles than your opponent before time runs out.
                            Both players get different sets of number tiles!
                        </ContentText>
                    </Container>
                    <Container $flexDirection={"row"} $gap={"7px"}>
                        <ContentText>Pressure: </ContentText>
                        <ContentText>
                            Fill all the points with your color.
                            Both players will get different sets of number tiles.
                            You can take over your opponents point. The pressure is on!
                        </ContentText>
                    </Container>
                </HowToPlayContent>
                <LineSpace $width={"5px"}/>
                <HowToPlayButtonContainer>
                    <HowToPlayButton onClick={handleClickGotIt}>Got it! Do not show this again.</HowToPlayButton>
                </HowToPlayButtonContainer>
            </HowToPlayModal>
        </HowToPlayContainer>
    )
}

export { HowToPlay };