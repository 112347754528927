const URL = {
    local: {
        updateHs: process.env.REACT_APP_LOCAL_POST_URL,
        refreshToken: process.env.REACT_APP_LOCAL_REFRESH_URL,
        changePassword: process.env.REACT_APP_LOCAL_CHANGE_PASSWORD_URL,
        deleteUser: process.env.REACT_APP_LOCAL_DELETE_USER_URL,
        registerUser: process.env.REACT_APP_LOCAL_REGISTER_USER_URL,
        signIn: process.env.REACT_APP_LOCAL_SIGNIN_USER_URL,
        signOut: process.env.REACT_APP_LOCAL_SIGNOUT_USER_URL,
        postHsLeaderboard: process.env.REACT_APP_LOCAL_POST_HS_LEADERBOARD,
        getAllHs: process.env.REACT_APP_LOCAL_GET_ALL_HS_LEADERBOARD,
        getPlayerStats: process.env.REACT_APP_LOCAL_GET_PLAYER_STATS,
    },
    prod: {
        updateHs: process.env.REACT_APP_POST_URL,
        refreshToken: process.env.REACT_APP_REFRESH_URL,
        changePassword: process.env.REACT_APP_CHANGE_PASSWORD_URL,
        deleteUser: process.env.REACT_APP_DELETE_USER_URL,
        registerUser: process.env.REACT_APP_REGISTER_USER_URL,
        signIn: process.env.REACT_APP_SIGNIN_USER_URL,
        signOut: process.env.REACT_APP_SIGNOUT_USER_URL,
        postHsLeaderboard: process.env.REACT_APP_POST_HS_LEADERBOARD,
        getAllHs: process.env.REACT_APP_GET_ALL_HS_LEADERBOARD,
        getPlayerStats: process.env.REACT_APP_GET_PLAYER_STATS,
    },
}

const accessControlAllowOrigin = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://www.computiles.com"
const envUrls = process.env.NODE_ENV === "development" ? URL.local : URL.prod

export { accessControlAllowOrigin, envUrls };