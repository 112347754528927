import { useContext, useState } from "react";
import { Container, Heading, Input, Page, UserContext, accessControlAllowOrigin, envUrls } from "../../global";
import TopBar from "../../components/topbar/TopBar";
import { FormContainer, InputContainer, ProfileButton, TextError } from "./styles";
import { useLocation } from "react-router-dom";
import { refreshToken } from "./utils";

const ChangePassword = () => {
    const {user} = useContext(UserContext)
    const location = useLocation()
    const [passwordChanged, setPasswordChanged] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPassword2, setNewPassword2] = useState("")
    const [password, setPassword] = useState("")
    // forgot-password will provide a link to /change-password with query params
    // _id and token
    
    const changePassword = async (e) => {
        e.preventDefault()
        if (user.username) {
            try {
                if (newPassword === newPassword2 && newPassword.length > 0) {
                    const {refreshData, refreshStatus} = await refreshToken(user) 
                    if (refreshStatus === 200) {
                        const response = await fetch(envUrls.changePassword, {
                            method: "PUT",
                            headers: {
                            "Content-Type": "Application/json",
                            "Access-Control-Allow-Origin": accessControlAllowOrigin
                            },
                            credentials: "include",
                            body: JSON.stringify({
                                newPassword, 
                                password,
                                _id: user._id
                            })
                        })
                        const data = await response.json()
                        const status = response.status
                        if (status === 401) {
                            setErrorMessage(data)
                        } else {
                            setErrorMessage("")
                            setPasswordChanged(data)
                        }
                        console.log(data)
                    } else {
                        console.log(refreshData)
                    }
                } 
            } catch (err) {
                console.warn("Something's wrong")
            }
        } else {
            try {
                if (newPassword === newPassword2 && newPassword.length > 0) {
                    const response = await fetch(`${envUrls.changePassword}${location.search}`, {
                        method: "PUT",
                        headers: {
                        "Content-Type": "Application/json",
                        "Access-Control-Allow-Origin": accessControlAllowOrigin
                        },
                        credentials: "include",
                        body: JSON.stringify({
                            newPassword, 
                            password,
                        })
                    })
                    const data = await response.json()
                    const status = response.status
                    if (status === 401) {
                        setErrorMessage(data)
                    } else {
                        setErrorMessage("")
                        setPasswordChanged(data)
                    }
                }    
            } catch (err) {
                console.warn("Something's wrong")
            }
        }
    }

    return (
        <Page>
            { user.username ? 
                <TopBar navigateTo={"/profile"} title={"Change Password"}/> 
                : 
                <TopBar navigateTo={"."} title={"CompuTiles"}/> 
            }
            <FormContainer onSubmit={changePassword}>
                <Heading $size={"25px"}>Change Password</Heading>
                { passwordChanged && <TextError $warn={false} $size={"16px"}>{passwordChanged}</TextError> }
                { errorMessage && <TextError $warn={true} $size={"16px"}>{errorMessage}</TextError> }
                { user.username && 
                    <InputContainer>
                        <Heading>Current Password</Heading>
                        <Input
                            autoCapitalize={"none"}
                            autoCorrect={"false"}
                            inputMode={"text"}
                            onChange={(e) => setPassword(e.target.value)}
                            type={"password"}
                            value={password} 
                        />
                    </InputContainer>
                }
                <InputContainer>
                    <Container $flexDirection={"column"}>
                        <Heading>New Password</Heading> 
                        <Heading $size={"16px"}>- Must be at least 8 characters long</Heading> 
                    </Container>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setNewPassword(e.target.value)}
                        type={"password"}
                        value={newPassword} 
                    />
                </InputContainer>
                <InputContainer>
                    <Heading>Re-type new password</Heading>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setNewPassword2(e.target.value)}
                        type={"password"}
                        value={newPassword2} 
                    />
                </InputContainer>
                {newPassword === newPassword2 ? 
                    newPassword.length > 0 && <TextError $warn={false} $size={"16px"}>Passwords match!</TextError> 
                    : 
                    <TextError $warn={true} $size={"16px"}>Passwords don't match!</TextError>
                }
                <ProfileButton type={"submit"} onClick={changePassword}>Change Password</ProfileButton>
            </FormContainer>
        </Page>
    )
}

//IF QUERY PARAM has access token from the link created by forgot password creator
//Then dont show "Current Password"
//If signed in and change password, show input for current password
//and in the backend, check for access token / refresh if required. 

export default ChangePassword;