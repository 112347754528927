import { createContext, useEffect, useState } from "react";
import { BlueTheme, GrayTheme, GreenTheme, PurpleTheme, RedTheme } from "../../../global/styles/themes";
import { colors } from "../../../global/styles/base";
import { defaultKeyboardMap } from "../../utils";

export const ThemeContext = createContext()

const ThemeContextProvider = ({children}) => {
  const [theme, setTheme] = useState({color: "blue", dark: false, tutorial: true, sound: true, keyboardMap: defaultKeyboardMap})
  useEffect(() => {
    getTheme()
    return () => {}
  }, [])

  function getTheme() {
    let tempTheme = JSON.parse(localStorage.getItem("Theme"))
    if (tempTheme) {
      if (tempTheme.keyboardMap === undefined) {
        setTheme({...tempTheme, keyboardMap: defaultKeyboardMap})
      } else {
        setTheme(tempTheme)
      }
    } else {
      localStorage.setItem("Theme", JSON.stringify({color: "blue", dark: false, tutorial: false, sound: true, keyboardMap: defaultKeyboardMap}))
    }
  }

  const [myTheme, setMyTheme] = useState(GrayTheme)
  useEffect(() => {
    if (theme) {
      if (theme.color === "blue") {
        setMyTheme(BlueTheme)
      } else if (theme.color === "purp") {
        setMyTheme(PurpleTheme)
      } else if (theme.color === "gree") {
        setMyTheme(GreenTheme)       
      } else if (theme.color === "red") {
        setMyTheme(RedTheme)       
      } else if (theme.color === "gray") {
        setMyTheme(GrayTheme)
      }
      if (theme.dark) {
        setMyTheme(prevState => ({...prevState, colors: {...prevState.colors, background: colors.DARK, title: colors.LIGHT }}))
      } else if (!theme.dark) {
        setMyTheme(prevState => ({...prevState, colors: {...prevState.colors, background: colors.LIGHT} }))        
      }
    }
    return () => {}
  }, [theme.color, theme.dark])

  return (
      <ThemeContext.Provider value={{theme, setTheme, myTheme}}>
          {children}
      </ThemeContext.Provider>
  )
}

export default ThemeContextProvider;