import { keyframes, styled } from "styled-components";
import { APP, BTN } from "./base";
import { Link } from "react-router-dom";

const Button = styled.button`
    align-items: center;
    background-color: ${props => props.$active === true ? props.theme.card : props.theme.btn};
    border-color: ${props => props.theme.title};
    border-radius: ${BTN.BORDER_RADIUS}px;
    border-style: ${BTN.BORDER_STYLE};
    border-width: ${BTN.BORDER_WIDTH}px;
    cursor: pointer;
    display: flex;
    flex: ${props => props.$flex};
    font-size: 18px;
    justify-content: center;
    min-height: 45px;
    text-align: center;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: ${props => props.theme.card};
            color: ${props => props.theme.lightText};
        }
    }
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex: ${props => props.$flex};
    gap: ${props => props.$gap};
    flex-direction: ${props => props.$flexDirection};
    flex-wrap: ${props => props.$flexWrap};
    justify-content: ${props => typeof props.$justify === "string" ? props.$justify : "center" };
    width: ${props => typeof props.$width === "string" ? props.$width : "100%" };
`;

const Heading = styled.p`
    color: ${props => props.theme.title};
    font-family: "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: ${props => props.$weight};
    font-size: ${props => typeof props.$size === "string" ? props.$size : "18px"};
    margin: 0;
    text-align: center;
`;

const ClickableLink = styled(Link)`
    font-family: "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-weight: ${props => props.$weight};
    font-size: ${props => typeof props.$size === "string" ? props.$size : "18px"};
    margin: 0;
    text-align: center;
    text-decoration: none;
    color: #4e88fc;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const ClickableText = styled(Heading)`
    color: #4e88fc;
    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const Input = styled.input`
    width: 150px;
    height: 25px;
`;

const fadeIn = keyframes`
    0% {
        scale: 0.5;
        opacity: 0;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
`;

const Modal = styled(Container)`
    animation-name: ${fadeIn};
    animation-duration: 2s;
    background-color: ${props => props.theme.background};
    border-color: ${props => props.theme.title};
    border-radius: ${BTN.BORDER_RADIUS}px;
    border-style: ${BTN.BORDER_STYLE};
    border-width: ${BTN.BORDER_WIDTH*3}px;
    height: ${props => typeof props.$height === "string" ? props.$height : "70%"};
    width: ${props => typeof props.$width === "string" ? props.$width : "70%"};
    max-width: ${APP.MAX_WIDTH-20}px;
    min-width: ${APP.MIN_WIDTH-20}px;
    position: absolute;
    z-index: 3;
`;

const Page = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100 * var(--vh));
    justify-content: center;
    max-width: ${APP.MAX_WIDTH}px;
    min-height: ${APP.MIN_HEIGHT}px;
    min-width: ${APP.MIN_WIDTH}px;
`;

const LineSpace = styled.div`
    border-color: ${props => props.theme.title};
    border-bottom-style: solid;
    border-width: ${props => props.$width ? props.$width : "2px"};
    height: 2px;
    width: 90%;
`;

export {
    Button,
    ClickableLink,
    ClickableText,
    Container,
    Heading,
    Input,
    LineSpace,
    Modal,
    Page,
    fadeIn,
};