const defaultKeyboardMap = {
    numTiles: {
        "KeyQ": 0,
        "KeyW": 1,
        "KeyE": 2,
        "KeyA": 3,
        "KeyS": 4,
        "KeyD": 5, 
    },
    opTiles: {
        "KeyJ": "opTileAdd",
        "KeyK": "opTileSub",
        "KeyL": "opTileMul",
        "Semicolon": "opTileDiv",
        "Quote": "opTileExp",
    },
    otherTiles: {
        "KeyM": "rerollTile",
        "Comma": "resetTile",
        "Period": "undoTile",
    },
}

export {
    defaultKeyboardMap,
};