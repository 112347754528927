import { OtherTileButton } from "./styles";

const Reroll = ({
    disabled,
    handleClick,
    isMobile,
    $disabledRR,
    $multi,
    $buzzer,
    $clicked
}) => {
    return(
        <OtherTileButton 
            $clicked={$clicked}
            $multi={$multi} 
            $buzzer={$buzzer} 
            $disabledRR={$disabledRR} 
            disabled={disabled} 
            onClick={isMobile ? undefined : handleClick}
            onTouchStart={isMobile && !disabled && !$disabledRR ? handleClick : undefined}
        >
            REROLL
        </OtherTileButton>
    )
}

export default Reroll;