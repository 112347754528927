import React from "react";
import { ThemeButton } from "../styles";

const DarkMode = ({theme, setTheme, $color, value}) => {
    function handleClickDark() {
        setTheme(prevState => ({...prevState, dark: value}))
        saveTheme()
    }
    function saveTheme() {
        let tempTheme = theme
        theme.dark = value
        localStorage.setItem("Theme", JSON.stringify(tempTheme))
    }

    let active = false;
    if (theme.dark === value) {
        active = true; 
    }

    return <ThemeButton $color={$color} $active={active} onClick={handleClickDark}/>
}

export default DarkMode;