export const colors = {
    LIGHT_PURPLE: "rgba(225, 185, 255, 1)", //purple
    DARK_PURPLE: "rgba(140, 65, 190, 1)", //dark purple
    LIGHT_BLUE: "rgba(160, 215, 255, 1)", //blue
    DARK_BLUE: "rgba(0, 100, 175, 1)", //dark blue
    LIGHT_GREEN: "rgba(185, 225, 185, 1)", //green
    DARK_GREEN: "rgba(0, 120, 0, 1)", //dark green
    LIGHT_RED: "rgba(255, 175, 175, 1)", //red
    DARK_RED: "rgba(180, 60, 60, 1)", //dark red
    LIGHT_GRAY: "rgba(200, 200, 200, 1)", //light gray
    GRAY: "rgba(115, 115, 115, 1)", // gray
    DARK: "rgba(25, 25, 25, 1)", //dark gray nightmode
    LIGHT: "rgba(240, 240, 240, 1)", //light gray morningmode
    CLICKED_GRAY: "rgba(155, 155, 155, 1)", //numtile clicked gray
    HOVERED_GRAY: "rgba(90, 90, 90, 1)", //numtile clicked gray
    BLACK: "rgba(0, 0, 0, 1)", //black
    WHITE: "rgba(255, 255, 255, 1)", //white
    WARNING_RED: "rgba(255, 120, 120, 1)",
    MODAL_GRAY: "rgba(120, 120, 120, .95)",
    HAPPY_GREEN: "rgba(0, 225, 100, 1)",
}

export const BTN = {
    BORDER_STYLE: "solid",
    BORDER_RADIUS: 10,
    BORDER_WIDTH: 2,
}

export const APP = {
    ASPECT_RATIO: 0.5625,
    MAX_WIDTH: 700,
    MIN_WIDTH: 320,
    MIN_HEIGHT: 550,
}

export const SETTINGS = {
    MAX_TARGET_VALUE: 120,
    MAX_NUMTILE_VALUE: 9,
    NUMTILE_QUANTITY: 6,
}

export const TARGET_ANIMATION_TIMES = {
    LENGTH: 2,
}

