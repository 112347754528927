import { keyframes, styled } from "styled-components";
import { Button, Container, Page } from "../../global/styles/styles";
import { APP } from "../../global/styles/base";

const ThemeButton = styled.button`
    border-width: 4px; 
    border-radius: 3px;
    border-style: solid;
    height: 40px; 
    width: 40px;
    background: ${props => props.$color};
    border-color: ${props => props.$active ? props.theme.title : props.theme.background};
    cursor: pointer;
    &:hover {
        border-color: ${props => props.theme.title};
    }
`;

const ContainerThemeButtons = styled(Container)`
    width: 70%;
    min-width: ${APP.MIN_WIDTH}px;
    justify-content: space-evenly;
`;

const radiate = (color1, color2) => keyframes`
    0% {
        background-color: ${color1};
    }
    100% {
        background-color: ${color2};
    }
`;

const ThemePage = styled(Page)`
    animation-name: ${props => radiate(props.$color1, props.$color2)};
    animation-duration: 3s;
`;

const ToggleBoardButton = styled(Button)`
    height: 30px;
    min-height: 20px;
    font-size: 16px;
`;

export {
    ContainerThemeButtons,
    ThemeButton,
    ThemePage,
    ToggleBoardButton,
};