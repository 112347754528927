import { useContext, useState } from "react";
import BotBar from "../../components/botbar/BotBar";
import { ClickableText, Container, Heading, Input, Page, UserContext, accessControlAllowOrigin, envUrls } from "../../global";
import { FormContainer, InputContainer, ProfileButton, TextError } from "./styles";

const SignIn = ({setShowSignUp}) => {
    const user = useContext(UserContext)
    const [errorMessage, setErrorMessage] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    function handleClickSignUp() {
        setShowSignUp(true)
    }

    const signIn = async (e) => {
        e.preventDefault()
        try {
            if (username.length > 0 && password.length > 0) {
                const response = await fetch(envUrls.signIn, {
                    method: "POST",
                    headers: {
                        "Content-Type": "Application/json",
                        "Access-Control-Allow-Origin": accessControlAllowOrigin
                    },
                    credentials: "include",
                    body: JSON.stringify({username, password})
                    })
                const data = await response.json()
                const status = response.status
                if (status === 401) {
                    setErrorMessage(data)
                } else {
                    setErrorMessage("")
                    user.setUser(data)
                    localStorage.setItem("SignedInUser", JSON.stringify(data))
                }
            }    
        } catch (err) {
            console.warn("Something's wrong")
        }
    }

    return (
        <Page>
            <FormContainer onSubmit={signIn}>
                <Heading $size={"25px"}>Sign In</Heading>
                { errorMessage && <TextError $warn={true} $size={"16px"}>{errorMessage}</TextError> }
                <InputContainer>
                    <Heading>Username or email</Heading>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setUsername(e.target.value)}
                        value={username} 
                    />
                </InputContainer>
                <InputContainer>
                    <Heading>Password</Heading>
                    <Input
                        autoCapitalize={"none"}
                        autoCorrect={"false"}
                        inputMode={"text"}
                        onChange={(e) => setPassword(e.target.value)}
                        type={"password"}
                        value={password} 
                    />
                </InputContainer>
                {/* <ClickableLink to={"./forgot-password"} $size={"16px"}>Forgot Password?</ClickableLink> */}
                <ProfileButton type="submit" onClick={signIn}>Sign In</ProfileButton>
                <Container $flexDirection={"row"} $gap={"7px"}>
                    <Heading $size={"16px"}>New user?</Heading>
                    <ClickableText $size={"16px"} onClick={handleClickSignUp}>Sign Up</ClickableText>
                </Container>
            </FormContainer>
            <Container>
                <BotBar/>
            </Container>
        </Page>
    )
}

export default SignIn;