import { Container, Heading } from "../../global";
import { ScoreDot } from "./styles";

const MultiScoreDot = ({player, color, $size, score, scoreToWin}) => {
    const scoreDots = []
    for (let i = 0; i < scoreToWin; i++) {
        scoreDots.push(
            <ScoreDot color={color.card} key={i} $size={$size} $score={score} $id={i}/>
        )
    }
    return (
        <Container $flex={1} $flexDirection={"column"}>
            <Heading>{player}</Heading>
            <Container $gap={"2px"}>
                {scoreDots}
            </Container>
        </Container>
    )
}

export default MultiScoreDot;