import { BuzzerButton } from "./styles";

const Buzzer = ({disabled, onClick, isMobile}) => {
    return (
        <BuzzerButton 
            disabled={disabled}
            onClick={isMobile ? undefined : onClick}
            onTouchStart={isMobile && !disabled ? onClick : undefined}
        >
            {disabled ? "Waiting for the opponent..." : "Got it!"}
        </BuzzerButton>
    )
}

export default Buzzer;