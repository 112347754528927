import { NumTileButton } from "./styles";

const NumTile = ({
    clicked,
    disabled,
    handleClick,
    isMobile,
    value,
    $invisible,
    $multi,
}) => {
    return (
        <NumTileButton 
            $multi={$multi}
            disabled={$invisible || disabled}
            $clicked={clicked} 
            $invisible={$invisible}
            onClick={isMobile ? undefined : handleClick}
            onTouchStart={isMobile && !disabled && !$invisible ? handleClick : undefined}
        >
                {(!disabled || $multi) && value}
        </NumTileButton>
    )
}

export default NumTile;