import NumTile from "./numtile/NumTile";
import { ContainerBuzzer, ContainerGameboard, ContainerNumTiles, ContainerOpTiles, ContainerOtherTiles } from "./styles";
import OpTile from "./optile/OpTile";
import { opTileClean, updatePlayerClicked } from "../../global/utils/utils";
import { OpContainer, OpIndicator } from "./optile/styles";
import Buzzer from "../buzzer/Buzzer";
import { Container } from "../../global";
import Reset from "./othertiles/Reset";
import Undo from "./othertiles/Undo";
import Reroll from "./othertiles/Reroll";

const MultiGameboardBzz = ({
    player,
    buzz,
    buzzerDisabled,
    disabled,
    isMobile,
    numTileClicked,
    numTileInfo,
    opTileClicked,
    opUsed,
    setBuzz,
    setBuzzDisabled,
    setVoteReroll,
    voteReroll,
    RESET_TILES,
    UPDATE_EQUATION,
    UNDO_TILES,
}) => { 
    const numTiles = numTileInfo.map((tile, index) => (
        <NumTile 
            $multi={true}
            key={tile.id} 
            disabled={disabled}
            {...numTileInfo[tile.id]} 
            handleClick={() => UPDATE_EQUATION({tile})}
            clicked={numTileClicked[index]}
            isMobile={isMobile}
        />
    ))
    const opTiles = Object.keys(opTileClean).map((op, index) => (
        <OpContainer $multi={true} key={op}>
            <OpTile 
                $multi={true}
                disabled={disabled}
                {...opTileClean[op]}
                handleClick={() => UPDATE_EQUATION({tile: op})}
                clicked={opTileClicked[index]}
                isMobile={isMobile}
            />
            <OpIndicator $used={opUsed[index]} $overFour={opUsed.filter((a) => a>0).length}/>
        </OpContainer>
    ))
    const allClicked = {numTileClicked, opTileClicked}
    if (player) updatePlayerClicked({player, clicked: allClicked})

    function handleClickBuzzer() {
        setBuzz(true)
        setBuzzDisabled(true)
    }

    function handleClickOtherTile(tile) {
        if (tile === "rerollTile") {
            setVoteReroll(prevState => !prevState)
        } else if (tile === "resetTile") {
            RESET_TILES()
        } else if (tile === "undoTile") {
            UNDO_TILES()
        }
    }

    return ( 
        <ContainerGameboard $flexDirection={"column"} $flex={1} $multi={true}>
            <ContainerNumTiles $flexWrap={"wrap"} $flex={1}>
                {numTiles}
            </ContainerNumTiles>
            <Container $flexDirection={"column"}>
                { !buzz &&
                    <ContainerBuzzer>
                        <Buzzer disabled={buzzerDisabled} onClick={handleClickBuzzer} isMobile={isMobile}/>
                        <Reroll isMobile={isMobile} $clicked={voteReroll} disabled={buzzerDisabled} $disabledRR={buzzerDisabled} $multi={true} $buzzer={true} handleClick={() => handleClickOtherTile("rerollTile")}/>
                    </ContainerBuzzer>
                }
                <ContainerOpTiles $flex={1}>
                    {opTiles}
                </ContainerOpTiles>
                <ContainerOtherTiles $flex={1}>
                    <Reset $multi={true} disabled={disabled} isMobile={isMobile} handleClick={() => handleClickOtherTile("resetTile")}/>
                    <Undo $multi={true} disabled={disabled} isMobile={isMobile} handleClick={() => handleClickOtherTile("undoTile")}/>
                </ContainerOtherTiles>
            </Container>
        </ContainerGameboard>
    )
}

export default MultiGameboardBzz;