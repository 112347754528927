import { styled } from "styled-components";
import { Heading } from "../../global";

const ContainerTopBar = styled.div`
    display: flex;
    min-height: 40px;
    max-height: 55px;
    height: 7vh;
    width: 100%;
    align-self: flex-start;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.card};
    position: ${props => props.$absolute ? "absolute" : null};
    top: ${props => props.$absolute ? 0 : null};
`;

const TextTopBar = styled(Heading)`
    color: ${props => props.theme.lightText}
`;

export {
    ContainerTopBar,
    TextTopBar,
};