import styled from "styled-components";
import { APP, Button, Container, colors } from "../../global";

const BuzzerButton = styled(Button)`
    background-color: ${props => props.disabled === true ? colors.CLICKED_GRAY : props.theme.btn};
    color: ${props => props.disabled === true ? props.theme.lightText : props.theme.darkText};
    cursor: ${props => props.disabled === true ? "default" : "pointer"};
    width: 100%;
    height: 38%;
`;

const BuzzerBackground = styled(Container)`
    z-index: 2;
    background-color: rgba(100,100,100,.35);
    height: 100%;
    max-width: ${APP.MAX_WIDTH}px;
    min-height: ${APP.MIN_HEIGHT/2}px;
    min-width: ${APP.MIN_WIDTH}px;
    width: 95%;
    position: absolute;
`;

export {
    BuzzerBackground,
    BuzzerButton,
}