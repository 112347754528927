import { styled } from "styled-components";
import { Button, Container } from "../../global/styles/styles";
import { APP, colors } from "../../global";

const ContainerNumTiles = styled(Container)`
    flex-wrap: wrap;
    gap: 5.5px;
    max-width: 450px;
`;

const GameboardButton = styled(Button)`
    background-color: ${props => props.$clicked === true ? props.theme.card : props.theme.background};
    // background-color: ${props => props.$clicked === true ? colors.CLICKED_GRAY : props.theme.btn};
    color: ${props => props.$clicked === true ? props.theme.lightText : props.theme.title};
    transition: transform 100ms ease-in-out;
    // mobile
    &:active {
        transform: scale(0.95);
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: ${props => props.$clicked === true ? colors.HOVERED_GRAY : props.theme.primary };
            color: ${props => props.$clicked === true ? props.theme.lightText : props.theme.darkText};
            transform: scale(1.07);
        }
        // web
        &:active {
            transform: scale(0.98);
        }
    }
`;

const ContainerOpTiles = styled(Container)`
    flex-wrap: nowrap;
    gap: 5.5px;
    max-width: 370px;
`;

const ContainerOtherTiles = styled(Container)`
    flex-wrap: nowrap;
    justify-content: space-evenly;
    max-width: 450px;
`;

const ContainerGameboard = styled(Container)`
    gap: ${props => props.$multi ? "2vh" : "20px"};
`;

const ContainerBuzzer = styled(Container)`
    flex-direction: column;
    position: absolute;
    height: 50%;
    width: 95%;
    max-width: 450px;
    min-width: ${APP.MIN_WIDTH-10}px;
    gap: 5px;
    z-index: 3;
`;

export {
    ContainerBuzzer,
    ContainerGameboard,
    ContainerNumTiles,
    ContainerOpTiles,
    ContainerOtherTiles,
    GameboardButton
};