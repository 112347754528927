import Main from "./Main";
import ThemeContextProvider from "./global/contexts/themecontext/ThemeContext";
import UserContextProvider from "./global/contexts/usercontext/UserContext";

function App() {
  // disable pinch multitouch and refresh on pulldown
  window.addEventListener("touchmove", (e) => {e.preventDefault()}, {passive: false});

  return (
    <UserContextProvider>
      <ThemeContextProvider>
          <Main/>
      </ThemeContextProvider>
    </UserContextProvider>
  );
}

export default App;
