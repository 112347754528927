import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { checkAns, useNumtiles } from "./utils";
import { BlueTheme, Container, Page, RedTheme, SETTINGS, TARGET_ANIMATION_TIMES } from "../../global/styles";
import { randomNumberToMax } from "../../global/utils";
import Target from "../../components/target/Target";
import { TargetContainer } from "./styles";
import { ThemeContext } from "../../global";
import ScoreSound from "../../assets/sounds/score-sound.wav"
import GameEndSound from "../../assets/sounds/game-end.wav"
import MultiGameboardBzz from "../../components/gameboard/MultiGameboardBzz";
import MidBar from "../../components/multimidbar/MidBar";
import { BuzzerBackground } from "../../components/buzzer/styles";
import { MultiplayerEndModal } from "../../components/endmodal/MultiplayerEndModal";
import MultiScoreDot from "../../components/score/MultiScoreDot";
import { ThemeProvider } from "styled-components";

const MultiBuzzer = ({gameSettings, isMobile}) => {
    let tempTime = TARGET_ANIMATION_TIMES.LENGTH-.5
    const {theme} = useContext(ThemeContext)
    const sound = theme.sound
    const navigate = useNavigate()
    const [scoreSound] = useSound(ScoreSound, {soundEnabled: sound})
    const [gameEndSound] = useSound(GameEndSound, {soundEnabled: sound, volume: 0.25})
    const [startTimer, setStartTimer] = useState(false)
    const [timerFinished, setTimerFinished] = useState(null)    
    const {title, difficulty, difficultyString, timeToSolve, point} = gameSettings
    const [disabled1, setDisabled1] = useState(true) 
    const [disabled2, setDisabled2] = useState(true) 
    const [winner, setWinner] = useState()
    const [showEndModal, setShowEndModal] = useState(false)
    const [target, setTarget] = useState(() => randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty}))
    const {
        state: {numTileInfo, undoArray, ans, usedOp, numTileClicked, opTileClicked},
        NEW_TILES, RESET_TILES, UNDO_TILES, UPDATE_EQUATION
    } = useNumtiles()

    const [score1, setScore1] = useState(0)
    const [score2, setScore2] = useState(0)
    const [voteReroll1, setVoteReroll1] = useState(false)
    const [voteReroll2, setVoteReroll2] = useState(false)
    const [buzz1, setBuzz1] = useState(false)
    const [buzz2, setBuzz2] = useState(false)     
    const [buzzDisabled1, setBuzzDisabled1] = useState(false)
    const [buzzDisabled2, setBuzzDisabled2] = useState(false)

    const copy = [
        {
            id: 0,
            value: undoArray[0]?.numTiles[0].value,
            $invisible: false,
        },
        {
            id: 1,
            value: undoArray[0]?.numTiles[1].value,
            $invisible: false,
        },
        {
            id: 2,
            value: undoArray[0]?.numTiles[2].value,
            $invisible: false,
        },
        {
            id: 3,
            value: undoArray[0]?.numTiles[3].value,
            $invisible: false,
        },
        {
            id: 4,
            value: undoArray[0]?.numTiles[4].value,
            $invisible: false,
        },
        {
            id: 5,
            value: undoArray[0]?.numTiles[5].value,
            $invisible: false,
        },
    ]

    function handleQuit() {
        if (isMobile && document.fullscreenElement) {
            document.exitFullscreen()
        }
        navigate("/")
    }

    function resetBuzzer() {
        setVoteReroll1(false)
        setVoteReroll2(false)
        setBuzz1(false) 
        setBuzz2(false)
        setBuzzDisabled1(false)
        setBuzzDisabled2(false)
        setStartTimer(false)
    }

    function handlePlayAgain() {
        const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
        setTarget(tempTarget)
        NEW_TILES({difficulty, target: tempTarget})
        setShowEndModal(false)
        resetBuzzer()
        setDisabled1(false)
        setDisabled2(false)
        setScore1(0)
        setScore2(0)
    }

    useEffect(() => {
        NEW_TILES({difficulty, target})
        return () => {}
    }, [])

    useEffect(() => { 
        if (ans === target) {
            const increaseScore = checkAns({difficulty, numTileInfo, opUsed: usedOp})
            if (increaseScore) {
                if (buzz1) setScore1(prevState => prevState + 1)
                if (buzz2) setScore2(prevState => prevState + 1)
                scoreSound()
                setStartTimer(false)
                const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
                setTarget(tempTarget)
                NEW_TILES({difficulty, target: tempTarget})
                resetBuzzer()
            } 
        }
        return () => {}
    }, [ans])

    useEffect(() => { 
        if (score1.toString() === point) {
            setShowEndModal(true)
            setWinner("Blue wins!")
            setBuzzDisabled1(true)
            setBuzzDisabled2(true)
            setDisabled1(true)
            setDisabled2(true)
            gameEndSound()
        } else if (score2.toString() === point) {
            setShowEndModal(true)
            setWinner("Red wins!")
            setBuzzDisabled1(true)
            setBuzzDisabled2(true)
            setDisabled1(true)
            setDisabled2(true)
            gameEndSound()
        }
        return () => {}
    }, [score1, score2])

    useEffect(() => { 
        let timerId;
        timerId = setInterval(() => {
            tempTime -= .5
            if (tempTime <= 0) {
                clearInterval(timerId)
                setDisabled1(false)
                setDisabled2(false)
                }
        }, 500);
        return () => {clearInterval(timerId)}
    }, [])

    useEffect(() => {
        if ((voteReroll1 && voteReroll2) || (voteReroll1 && buzzDisabled2) || (voteReroll2 && buzzDisabled1)) {
            const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
            setTarget(tempTarget)
            NEW_TILES({difficulty, target: tempTarget})
            resetBuzzer()
        } 
        return () => {}
    }, [voteReroll1, voteReroll2])

    useEffect(() => {
        if (timerFinished && buzz1) {
            setBuzzDisabled1(true)
            setBuzzDisabled2(false)
        } else if (timerFinished && buzz2) {
            setBuzzDisabled2(true)
            setBuzzDisabled1(false)
        }
        if (timerFinished) {
            setStartTimer(false)
            setBuzz1(false) 
            setBuzz2(false)
            RESET_TILES() 
        } 
        return () => {}
    }, [timerFinished])

    useEffect(() => {
        if (buzz1) {
            setDisabled1(false)
            setBuzzDisabled1(false)
            setDisabled2(true)
            setStartTimer(true)
            setTimerFinished(false)
        } else if (buzz2) {
            setDisabled1(true)
            setDisabled2(false)
            setBuzzDisabled2(false)
            setStartTimer(true)
            setTimerFinished(false)
        }
        setVoteReroll1(false)
        setVoteReroll2(false)
        return () => {}
    }, [buzz1, buzz2])

    return (
        <Page>
            {showEndModal && <MultiplayerEndModal 
                score1={score1}
                score2={score2}
                winner={winner}
                handlePlayAgain={handlePlayAgain}
                handleQuit={handleQuit}
            />}
            <Container $flex={1} $gap={"1vh"} $flexDirection={"column"} style={{transform: "rotate(180deg)"}}>
                {!buzz2 && <BuzzerBackground/>}
                <Container $flex={1}>
                    <MultiScoreDot color={RedTheme.colors} player={"You"} score={score2} scoreToWin={point}/>
                    <TargetContainer $flex={1}>
                        <Target $size={"6.5vh"} target={target}/>
                    </TargetContainer>
                    <MultiScoreDot color={BlueTheme.colors} player={"Opponent"} score={score1} scoreToWin={point}/>
                </Container>
                <Container $flex={6} style={{alignItems: "flex-start"}}>
                    <ThemeProvider theme={RedTheme.colors}>
                        <MultiGameboardBzz 
                            numTileInfo={buzz1 ? copy : numTileInfo}
                            numTileClicked={buzz1 ? [false, false, false, false, false, false] : numTileClicked} 
                            opTileClicked={buzz1 ? [false, false, false, false, false] : opTileClicked}
                            buzzerDisabled={buzzDisabled2} disabled={disabled2} 
                            buzz={buzz2} setBuzz={setBuzz2} setBuzzDisabled={setBuzzDisabled1}
                            voteReroll={voteReroll2} setVoteReroll={setVoteReroll2}
                            opUsed={usedOp} RESET_TILES={RESET_TILES}
                            UNDO_TILES={UNDO_TILES} UPDATE_EQUATION={UPDATE_EQUATION}
                            isMobile={isMobile}
                        />
                    </ThemeProvider>
                </Container>
            </Container>
            <MidBar 
                $size={"10px"} 
                startTimer={startTimer} 
                time={timeToSolve} 
                title={difficultyString+" - "+title} 
                navigateTo={"/multiplayer"}
                setTimerFinished={setTimerFinished}
                isMobile={isMobile}
            />
            <Container $flex={1} $gap={"1vh"} $flexDirection={"column"}  style={{transform: "rotate(360deg)"}}>
                {!buzz1 && <BuzzerBackground/>}
                <Container $flex={1}>
                    <MultiScoreDot color={RedTheme.colors} player={"Opponent"} score={score2} scoreToWin={point}/>
                    <TargetContainer $flex={1}>
                        <Target $size={"6.5vh"} target={target}/>
                    </TargetContainer>
                    <MultiScoreDot color={BlueTheme.colors} player={"You"} score={score1} scoreToWin={point}/>
                </Container>
                <Container $flex={6} style={{alignItems: "flex-start"}}>
                    <ThemeProvider theme={BlueTheme.colors}>
                        <MultiGameboardBzz
                            numTileInfo={buzz2 ? copy : numTileInfo}
                            numTileClicked={buzz2 ? [false, false, false, false, false, false] : numTileClicked} 
                            opTileClicked={buzz2 ? [false, false, false, false, false] : opTileClicked}
                            buzzerDisabled={buzzDisabled1} disabled={disabled1} 
                            buzz={buzz1} setBuzz={setBuzz1} setBuzzDisabled={setBuzzDisabled2}
                            voteReroll={voteReroll1} setVoteReroll={setVoteReroll1}
                            opUsed={usedOp} RESET_TILES={RESET_TILES} 
                            UNDO_TILES={UNDO_TILES} UPDATE_EQUATION={UPDATE_EQUATION}
                            isMobile={isMobile}   
                        />
                    </ThemeProvider>
                </Container>
            </Container>
        </Page>
    )
}

export default MultiBuzzer;