import { SETTINGS } from "../styles/base"
import abdEasy from "../../assets/abd/abdEasy.json"
import abdNormal from "../../assets/abd/abdNormal.json"
import abdHard from "../../assets/abd/abdHard.json"
import abdExpert from "../../assets/abd/abdExpert.json"

function randomNumberToMax({max, difficulty}) {
    if (difficulty === "e") {
        // this is the max number for easy
        max = 91;
    }
    return Math.floor(Math.random() * max + 1)
}

function numTileReroll({difficulty, target}) {
    let numTiles = []
    let numString = ""
    let database = {}
    switch (difficulty) {
        case "e":
            database = abdEasy
            break;
        case "m":
            database = abdNormal
            break;
        case "h":
            database = abdHard
            break;
        case "x":
            database = abdExpert
            break;
        default:
            database = abdHard
            break;
    }
    numString = database[target].solutions[Math.floor(Math.random() * database[target].solutions.length)].numberTiles
    numTiles = shuffleNumtiles(numString.split("")).map((value, id) => {
        return (
            {
                id,
                value,
                $invisible: false,
            }
        )
    })
    return {numTiles, numString}
}

function getSolution({difficulty, target, numTiles}) {
    let numString = [...numTiles].sort().join("")
    let database = {}
    switch (difficulty) {
        case "e":
            database = abdEasy
            break;
        case "m":
            database = abdNormal
            break;
        case "h":
            database = abdHard
            break;
        case "x":
            database = abdExpert
            break;
        default:
            database = abdHard
            break;
    }
    const ansIndex = database[target].solutions.findIndex((ans, index) => {
        return ans.numberTiles === numString && index
    })
    const solution = database[target].solutions[ansIndex].equation
    return solution
}

function shuffleNumtiles(numtiles) {
    const spots = [0,1,2,3,4,5]
    let tempNums = [...numtiles]
    for (let i = 0; i < numtiles.length; i++) {
        const place = randomNumberToMax({max: spots.length})-1
        tempNums[i] = numtiles[spots[place]];
        spots.splice(place,1)
    }
    return tempNums
}

function customNumTiles(customValuesArray, customInvisArray) {
    let numTiles = []
    if (arguments.length === 1) {
        for (let i = 0; i < SETTINGS.NUMTILE_QUANTITY; i++) {
            numTiles.push(
            {
                id: i,
                value: customValuesArray[i],
                $invisible: false,
            })
        }
    } else if (arguments.length === 2) {
        for (let i = 0; i < SETTINGS.NUMTILE_QUANTITY; i++) {
            numTiles.push(
            {
                id: i,
                value: customValuesArray[i],
                $invisible: customInvisArray[i],
            })
        }
    }
    return numTiles
}

const opTileClean = {
    "opTileAdd": {
        symbol: "+",
    },
    "opTileSub": {
        symbol: "-",
    },
    "opTileMul": {
        symbol: "x",
    },
    "opTileDiv": {
        symbol: "÷",
    },
    "opTileExp": {
        symbol: "^",
    },
}

function updatePlayerEquation({player, equation}) {
    player.equation = equation
}
function updatePlayerTiles({player, numTileInfo, opUsed}) {
    player.numTiles = numTileInfo
    player.opUsed = opUsed
}
function updatePlayerScore({player, score}) {
    player.score = score
}
function updatePlayerClicked({player, clicked}) {
    player.clickedTiles = clicked
}

export {
    customNumTiles,
    numTileReroll,
    getSolution,
    opTileClean,
    randomNumberToMax,
    updatePlayerClicked,
    updatePlayerEquation,
    updatePlayerScore,
    updatePlayerTiles
}