import { randomNumberToMax } from "../../global";

const inGamePhrases = [
    "Great!",
    "Wahoo!",
    "Genius!",
    "Superb!",
    "Nice one!",
    "Fantastic!",
    "Nice!",
    "Wow!",
    "Awesome!",
    "Very cool!",
]

const loadingPhrases = [
    "There's a solution! Don't quit!",
    "Take it easy in practice mode! No timer. No pressure.",
    "Test your skills in time trial!",
    "See how your highscores match up to others on the leaderboard.",
    "Challenge a friend in multiplayer!",
    "Too easy? Try out other difficulties for a challenge.",
    "Have you tried using the keyboard to play? Try it out!",
    "Did you know you can tap multiple number tiles before tapping an operation tile?",
    "The order that you tap the number tiles matters for subtraction, division, and exponents!",
]

function getPhrase(phrases) {
    return phrases[randomNumberToMax({max: phrases.length-1})]
}

export {
    getPhrase,
    inGamePhrases,
    loadingPhrases,
}

    
