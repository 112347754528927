import { OpTileButton } from "./styles";

const OpTile = ({
    symbol,
    clicked,
    disabled,
    handleClick,
    isMobile,
    $multi
}) => {
    return (
        <OpTileButton 
            $multi={$multi}
            disabled={disabled}
            $clicked={clicked} 
            onClick={isMobile ? undefined : handleClick}
            onTouchStart={isMobile && !disabled ? handleClick : undefined}
        >
            {symbol}
        </OpTileButton>
    )
}

export default OpTile;