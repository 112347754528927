import { Container, Heading } from "../../global/styles/styles";
import { HelperText } from "./styles";

const Description = ({gameSettings}) => {
    let mode;
    let timeToSolve;
    let points = 0; 
    let helperText = ""
    if (gameSettings.point) {
        points = gameSettings.point;
        timeToSolve = gameSettings.timeToSolve;
    }

    if (gameSettings.mode === "timeTrial") {
        mode = 
        <Container>
            <Heading $size={"20px"}>
                Timer: {gameSettings.time} seconds
            </Heading> 
        </Container>
    } else if (gameSettings.mode === "p") {
        mode = ""
    } else if (gameSettings.mode === "bzz") {
        mode = 
        <Container $flexDirection={"column"}>
            <Heading $size={"20px"}>
                Points to win: {points}
            </Heading>
            <Heading $size={"20px"}>
                Buzz in and tap in your answer within {timeToSolve} seconds.
            </Heading> 
        </Container>
        helperText = "Both people need to click reroll to get new number tiles."
    } else if (gameSettings.mode === "mtt") {
        mode = 
        <Container $flexDirection={"column"}>
            <Heading $size={"20px"}>
                Timer: {gameSettings.time} seconds
            </Heading> 
            <Heading $size={"20px"}>
                To win, solve more sets than your opponent before time runs out.
            </Heading>  
        </Container>
    } else if (gameSettings.mode === "pre") {
        mode = 
        <Container $flexDirection={"column"}>
            <Heading $size={"20px"}>
                Points to win: {gameSettings.point}
            </Heading> 
            <Heading $size={"20px"}>
                Fill all the points with your color.
            </Heading> 
        </Container>
    } else {
        mode = 
        <Container>
            <Heading $size={"20px"}>
                Something is wrong! Please reload the game.
            </Heading> 
        </Container>
    }

    // if (gameSettings.difficulty === "e") {
    //     difficulty = 
    //     <Container>
    //         <Heading $size={"20px"}>
    //             Compute to the target number. Answers will not re
    //         </Heading>
    //     </Container>
    // } else if (gameSettings.difficulty === "m") {
    //     difficulty = 
    //     <Container>
    //         <Heading $size={"20px"}>
    //             Use any operations and ALL number tiles.
    //         </Heading>
    //     </Container>
    // } else if (gameSettings.difficulty === "h") {
    //     difficulty = 
    //     <Container>
    //         <Heading $size={"20px"}>
    //             Use at least 4 DIFFERENT operations and ALL number tiles.
    //         </Heading>
    //     </Container>
    // } else {
    //     difficulty = 
    //     <Container>
    //         <Heading $size={"20px"}>
    //             Something is wrong! Please reload the game.
    //         </Heading> 
    //     </Container>
    // }

    return (
        <Container $flex={1} $flexDirection={"column"} $gap={"10px"}>
                {mode}
                <HelperText>
                    {helperText}
                </HelperText>
        </Container>
    )
}

export default Description;