import styled from "styled-components";
import { Button, Container, Heading, fadeIn } from "../../global";

const ProfileButton = styled(Button)`
    font-size: 16px;
    min-height: 40px;
    max-height: 45px;
    height: 10vw;
    min-width: 75px;
    max-width: 120px;
    width: 30vw;
`;

const FormContainer = styled.form`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    gap: 7px;
`;

const InputContainer = styled(Container)`
    flex-direction: column;
    gap: 2px;
`;

const TextError = styled(Heading)`
    animation-name: ${fadeIn};
    animation-duration: 1s;
    color: ${props => props.$warn ? props.theme.warning : props.theme.happy};
`;

export { 
    FormContainer,
    InputContainer,
    ProfileButton,
    TextError
};