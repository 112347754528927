import React, { useContext } from "react";
import { ContainerTopBar, TextTopBar } from "./styles";
import BackButton from "../backbutton/BackButton";
import { ContainerBackButton } from "../backbutton/styles";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../global/contexts/themecontext/ThemeContext";

const TopBar = ({title, navigateTo, isMobile}) => {
    const theme = useContext(ThemeContext)
    const navigate = useNavigate()
    function handleClick() {
        if (isMobile && document.fullscreenElement) {
            document.exitFullscreen()
        }
        navigate(navigateTo || -1)
    }
    return (
        <ContainerTopBar>
            <ContainerBackButton onClick={handleClick}>
                <BackButton size={"1.25em"} color={theme.myTheme.colors.lightText}/>
            </ContainerBackButton>
            <TextTopBar>
                {title}
            </TextTopBar>
            <ContainerBackButton/>
        </ContainerTopBar>
    )
}

export default TopBar;