import { styled } from "styled-components";
import { Button, Container, Heading } from "../../global/styles/styles";

const ContainerMenuButton = styled(Container)`
    width: 50vw;
    min-width: 250px;
    justify-content: space-evenly;
`;

const MenuButton = styled(Button)`
    font-size: 18px;
    width: 100%;
    max-width: 250px;
    max-height: 50px;
`;

const Title = styled(Heading)`
    justify-self: flex-end;
    font-size: 50px; 
    font-weight: 550;
`;

export {
    ContainerMenuButton,
    MenuButton,
    Title,
};