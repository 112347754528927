import styled from "styled-components";

const ScoreDot = styled.div`
    background-color: ${props => (props.$id < props.$score) && props.color};
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    border-radius: 12px;
    border-color: ${props => props.theme.title};
    border-style: solid;
    border-width: .5px;
`;

const PressureDot = styled(ScoreDot)`
    background-color: ${props => typeof props.color === "string" ? props.color : undefined};
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 16px;
    border-width: 1px;
`;

export {
    PressureDot,
    ScoreDot,
};