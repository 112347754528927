import { keyframes, styled } from "styled-components";

const fade = keyframes`
    0% {
        filter: opacity(0%);
        transform: translateY(20px);
    }
    10% {
        transform: translateY(0px);
    }
    50% {
        filter: opacity(100%);
    }
    90% {
        filter: opacity(100%);
        transform: translateY(0px);
    }
    100% {
        filter: opacity(0%);
        transform: translateY(20px);
    }
`;

const Phrase = styled.div`
    display: flex;
    width: 80%;
    justify-content: center;
    opacity: ${props => (props.$fade ? 1 : 0)};
    animation-name: ${props => props.$trigger ? null : (props.$fade ? fade : null)};
    animation-duration: ${props => props.$duration}s;
    align-items: center;
`;

export {
    Phrase,
};