import { Heading } from "../../global";

const Highscore = ({$size, highscore}) => {
    return (
        <>
            <Heading $size={$size}>Highscore: {highscore ? highscore : 0}</Heading>
        </>
    )
}

export default Highscore;