import { accessControlAllowOrigin, envUrls } from "../../global"
import { refreshToken } from "../profile/utils"

async function getAllHS(setAllHS) {
    try {
        const response = await fetch(envUrls.getAllHs, {
            method: "GET",
            headers: {
                "Content-Type": "Application/json",
                "Access-Control-Allow-Origin": accessControlAllowOrigin
            },
            credentials: "include",
        })
        const data = await response.json()
        setAllHS(data)
    } catch (err) {
        console.warn("Something's wrong")
    }
}

async function getAllHSUser({setAllHS, setUserStats, user}) {
    try {
        const response = await fetch(envUrls.getPlayerStats, {
            method: "POST",
            headers: {
                "Content-Type": "Application/json",
                "Access-Control-Allow-Origin": accessControlAllowOrigin
            },
            credentials: "include",
            body: JSON.stringify({
                "_id": user._id,
            })
        })
        const data = await response.json()
        setAllHS(data.separatedScores)
        setUserStats(data.userStats)
    } catch (err) {
        console.warn("Something's wrong", err)
    }
}

async function postHighScore({difficulty, setAllHS, setModal, time, user, mode}) {
    try {
        const {refreshData, refreshStatus} = await refreshToken(user) 
        if (refreshStatus === 200) {
            const response = await fetch(envUrls.postHsLeaderboard, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Origin": accessControlAllowOrigin
                    },
                    credentials: "include",
                body: JSON.stringify({
                    _id: user._id,
                    username: user.username,
                    gameDifficulty: difficulty,
                    gameTime: time,
                    gameMode: mode,
                })
            })
            const data = await response.json()
            const status = response.status
            if (status === 201) {
                await getAllHS(setAllHS)
            }
            setModal({show: true, string: data})
        } else {
            console.log(refreshData)
        }
    } catch {
        console.warn("Something's wrong")
    }
}

export {
    getAllHS,
    getAllHSUser,
    postHighScore
};