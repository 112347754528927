import { useContext } from "react";
import { Container, Heading, Page, UserContext, accessControlAllowOrigin, envUrls } from "../../global";
import TopBar from "../../components/topbar/TopBar";
import { ProfileButton } from "./styles";
import { useNavigate } from "react-router-dom";
import { refreshToken } from "./utils";

const DeleteUser = ({signOut}) => {
    const navigate = useNavigate()
    const {user} = useContext(UserContext)

    const deleteUser = async () => {
        try {
            const {refreshData, refreshStatus} = await refreshToken(user) 
            if (refreshStatus === 200) {
                const response = await fetch(envUrls.deleteUser, {
                    method: "DELETE",
                    headers: {
                    "Content-Type": "Application/json",
                    "Access-Control-Allow-Origin": accessControlAllowOrigin
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        "username": user.username, 
                        "_id": user._id
                    })
                })
                const data = await response.json()
                const status = response.status
                if (status === 401) {
                    console.log("401", data)
                } else {
                    console.log("200", data)
                }
                signOut()
            } else {
                console.log(refreshData)
            }
        } catch (err) {
            console.warn("Something's wrong")
        }
    }

    return (
        <Page>
            <TopBar navigateTo={"/profile"} title={"Delete Account"}/>
            <Container $flex={1}>
                <Container $flexDirection={"column"} $gap={"20px"}>
                    <Heading>Are you sure you want to delete your account?</Heading>
                    <Heading $size={"16px"}>All your account information including highscores will be erased permanently.</Heading>
                    <Container $gap={"10px"}>
                        <ProfileButton onClick={deleteUser}>Yes</ProfileButton>
                        <ProfileButton onClick={() => navigate("/profile")}>No</ProfileButton>
                    </Container>
                </Container>
            </Container>
        </Page>
    )
}

export default DeleteUser;