import { Link } from "react-router-dom";
import { styled } from "styled-components";

const ContainerBotBar = styled.div`
    display: flex;
    min-height: 40px;
    max-height: 55px;
    height: 8vh;
    width: 100%;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.card};
`;

const ItemBotBar = styled(Link)`
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: ${props => props.$active ? props.theme.btn : props.theme.card};
    color: ${props => props.$active ? props.theme.darkText : props.theme.lightText};
    font-family: "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    &.clicked, &:hover {
        background-color: ${props => props.theme.btn};
        color: ${props => props.theme.darkText};
    }
    p {
        margin: 0px 5px;
    }
    svg {
        margin: 5px;
    }
    @media screen and (max-width: 550px) {
        flex-direction: column;
        p {
          font-size: 14px;
          margin: 3px 2px;
        }
        svg {
            margin: 0px;
            margin-top: 5px;
        }
      }
`;

export {
    ContainerBotBar,
    ItemBotBar
};