import { useNavigate } from "react-router-dom";
import GameSetting from "../../components/gamesetting/GameSetting";
import TopBar from "../../components/topbar/TopBar";
import { Button, Container, Page } from "../../global/styles/styles";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../global/contexts/usercontext/UserContext";
import Description from "../../components/description/Description";
import Highscore from "../../components/highscore/Highscore";

const SinglePlayer = ({isMobile, isMobileSafari}) => {
    const navigate = useNavigate()
    const {user} = useContext(UserContext)
    const gameMode = JSON.parse(localStorage.getItem("hsMode")) || {}
    const [gameSettings, setGameSettings] = useState({
        title: "Easy",
        difficulty: gameMode.difficulty || "e",
        time: gameMode.time || 60,
        mode: gameMode.mode || "p",
    })
    const [highscore, setHighscore] = useState()
    const hsString = `hs${gameSettings.difficulty+gameSettings.time}`
    const hsMode = {}
    hsMode["string"] = hsString
    hsMode["difficulty"] = gameSettings.difficulty
    hsMode["time"] = gameSettings.time
    hsMode["mode"] = gameSettings.mode

    function handleClick() {
        try {
            if (isMobile) {
                document.body.requestFullscreen()
            } else if (isMobileSafari) {
                Element.webkitRequestFullscreen()
            }
        } catch {}
        finally {
            localStorage.setItem("hsMode", JSON.stringify(hsMode))
            navigate("/game", {state: {gameSettings}})
        }
    }
    
    useEffect(() => {
        setHighscore(user?.highscores?.[gameSettings.mode]?.[hsMode.string] || 0)
        return () => {}
    }, [gameSettings])
    
    useEffect(() => {
        switch (gameSettings.difficulty) {
            case "e":
                setGameSettings(prevState => ({...prevState, time: 60, title: "Easy"}))
                break;
            case "m":
                setGameSettings(prevState => ({...prevState, time: 90, title: "Normal"}))
                break;
            case "h":
                setGameSettings(prevState => ({...prevState, time: 120, title: "Hard"}))
                break;
        }
        return () => {}
    }, [gameSettings.difficulty])

    const difficultySetting = {
        title: "Difficulty",
        setting: "difficulty",
        buttons: [
            {
                text: "Easy",
                value: "e",
            },
            {
                text: "Normal",
                value: "m",
            },
            {
                text: "Hard",
                value: "h",
            },
        ]
    }

    const modeSetting = {
        title: "Game Mode",
        setting: "mode",
        buttons: [
            {
                text: "Practice",
                value: "p",
            },
            {
                text: "Time Trial",
                value: "timeTrial",
            },
        ]
    }

    return (
        <Page>
            <TopBar title={"Single Player"} navigateTo={"/"}/>
            <Container $flex={1}>
            </Container>
            <Container $flex={3}>
                <GameSetting setGameSettings={setGameSettings} $active={gameSettings.mode} settings={modeSetting}/>
            </Container>
            <Container $flex={3}>
                <GameSetting setGameSettings={setGameSettings} $active={gameSettings.difficulty} settings={difficultySetting}/>
            </Container>
            <Container $flex={6} $flexDirection={"column"}>
                {gameSettings.mode === "timeTrial" && 
                <Highscore highscore={user?.highscores[gameSettings.mode][hsString]}
                />}
                <Description gameSettings={gameSettings} highscore={highscore}/>
            </Container>
            <Container $flex={4} $width={"70%"}>
                <Button $flex={1} onClick={handleClick}>Play</Button>
            </Container>
        </Page>
    )
}

export default SinglePlayer;