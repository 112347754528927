import { useContext, useState } from "react";
import BotBar from "../../components/botbar/BotBar";
import { ClickableLink, Container, Heading, Page, UserContext, accessControlAllowOrigin, envUrls } from "../../global";
import Registration from "./Registration";
import SignIn from "./SignIn";
import { ProfileButton } from "./styles";

const ProfilePage = () => {
    const { user, setUser } = useContext(UserContext)
    const [showSignUp, setShowSignUp] = useState(false)

    const signOut = async () => {
        setUser(JSON.parse(localStorage.getItem("DefaultUser")))
        localStorage.removeItem("SignedInUser")
        fetch(envUrls.signOut, {
            method: "GET",
            headers: {
            "Content-Type": "Application/json",
            "Access-Control-Allow-Origin": accessControlAllowOrigin
            },
            credentials: "include",
        })
    }

    //Forgot password > new page
    //if user.username, show all details and then show delete user, change password, sign out

    return (
        <>
            {user.username ? 
                <Page>
                    <Container $flex={1.5} $flexDirection={"column"} $gap={"7px"}>
                    </Container>
                    <Container $flex={3} $flexDirection={"column"} $gap={"7px"}>
                        <Heading>Email: {user.email}</Heading>
                        <Heading>Username: {user.username}</Heading>
                        <ProfileButton onClick={signOut}>Sign Out</ProfileButton>
                    </Container>
                    <Container $flex={1} $flexDirection={"column"} $gap={"10px"}>
                        <ClickableLink to={"change-password"} $size={"16px"}>Change Password</ClickableLink>
                        <ClickableLink to={"delete-account"} $size={"16px"}>Delete Account</ClickableLink>
                    </Container>
                    <Container $flex={.5}>
                        <Heading $size={"14px"}>© 2024 CompuTiles. All rights reserved.</Heading>
                    </Container>
                    <Container>
                        <BotBar/>
                    </Container>
                </Page>
                :
                showSignUp ? 
                    <Registration setShowSignUp={setShowSignUp}/> :
                    <SignIn setShowSignUp={setShowSignUp}/>
            }
        </>
    )
}

export default ProfilePage;