import { colors } from "./base";

export const PurpleTheme = {
  dark: false,
  colors: {
    primary: colors.LIGHT_PURPLE, 
    btn: colors.LIGHT_PURPLE, 
    title: colors.DARK, 
    background: colors.LIGHT,
    card: colors.DARK_PURPLE, 
    text: colors.DARK,
    border: colors.BLACK,
    notification: colors.LIGHT,
    lightText: colors.LIGHT,
    darkText: colors.DARK,
    warning: colors.WARNING_RED,
    modal: colors.MODAL_GRAY,
    happy: colors.HAPPY_GREEN,
  },
};

export const BlueTheme = {
  dark: false,
  colors: {
    primary: colors.LIGHT_BLUE, //blue
    btn: colors.LIGHT_BLUE, //blue
    background: colors.LIGHT,
    title: colors.DARK, 
    card: colors.DARK_BLUE, //dark blue
    text: colors.DARK,
    border: colors.BLACK,
    notification: colors.LIGHT,
    lightText: colors.LIGHT,
    darkText: colors.DARK,
    warning: colors.WARNING_RED,
    modal: colors.MODAL_GRAY,
    happy: colors.HAPPY_GREEN,
  },
};

export const GreenTheme = {
  dark: false,
  colors: {
    primary: colors.LIGHT_GREEN, //green
    btn: colors.LIGHT_GREEN, //green
    background: colors.LIGHT,
    title: colors.DARK, 
    card: colors.DARK_GREEN, //dark green
    text: colors.DARK,
    border: colors.BLACK,
    notification: colors.LIGHT,
    lightText: colors.LIGHT,
    darkText: colors.DARK,
    warning: colors.WARNING_RED,
    modal: colors.MODAL_GRAY,
    happy: colors.HAPPY_GREEN,
  },
};

export const RedTheme = {
  dark: false,
  colors: {
    primary: colors.LIGHT_RED, //red
    btn: colors.LIGHT_RED, //red
    background: colors.LIGHT,
    title: colors.DARK, 
    card: colors.DARK_RED, //dark red
    text: colors.DARK,
    border: colors.BLACK,
    notification: colors.LIGHT,
    lightText: colors.LIGHT,
    darkText: colors.DARK,
    warning: colors.WARNING_RED,
    modal: colors.MODAL_GRAY,
    happy: colors.HAPPY_GREEN,
  },
};

export const GrayTheme = {
  dark: false,
  colors: {
    primary: colors.LIGHT_GRAY, //gray
    btn: colors.LIGHT_GRAY, //gray
    background: colors.LIGHT,
    title: colors.DARK, 
    card: colors.GRAY, //gray
    text: colors.DARK,
    border: colors.BLACK,
    notification: colors.LIGHT,
    lightText: colors.LIGHT,
    darkText: colors.DARK,
    warning: colors.WARNING_RED,
    modal: colors.MODAL_GRAY,
    happy: colors.HAPPY_GREEN,
  },
};
