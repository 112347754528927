import { styled } from "styled-components";
import { Button, Container } from "../../global/styles/styles";

const GameSettingsButton = styled(Button)`
    flex: 1;
    font-size: 17px;
    color: ${props => props.$active === true ? props.theme.lightText : props.theme.darkText};
    background-color: ${props => props.$active === true ? props.theme.card : props.theme.btn};
    &:hover {
        color: ${props => props.theme.lightText};
        background-color: ${props => props.theme.card};
    }
`;

const SettingButtonsContainer = styled(Container)`
    width: 95%;
    gap: 7px;
`;

const SettingsContainer = styled(Container)`
    gap: 7px;
`;

export {
    GameSettingsButton,
    SettingButtonsContainer,
    SettingsContainer,
};