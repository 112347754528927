import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { checkAns, postToDB, useNumtiles } from "./utils";
import { Container, Page, SETTINGS, TARGET_ANIMATION_TIMES } from "../../global/styles";
import { randomNumberToMax } from "../../global/utils";
import TopBar from "../../components/topbar/TopBar";
import Gameboard from "../../components/gameboard/Gameboard";
import Target from "../../components/target/Target";
import Phrases from "../../components/phrases/Phrases";
import { TargetContainer } from "./styles";
import Score from "../../components/score/Score";
import Highscore from "../../components/highscore/Highscore";
import Timer from "../../components/timer/Timer";
import { ThemeContext, UserContext } from "../../global";
import { EndModal } from "../../components/endmodal/EndModal";
import { inGamePhrases } from "../../components/phrases/utils";
import ScoreSound from "../../assets/sounds/score-sound.wav"
import NewHSSound from "../../assets/sounds/game-end-new-hs.wav"
import GameEndSound from "../../assets/sounds/game-end.wav"

const TimeTrial = ({gameSettings, isMobile}) => {
    let tempTime = TARGET_ANIMATION_TIMES.LENGTH-.5
    const {theme} = useContext(ThemeContext)
    const {sound, keyboardMap} = theme
    const navigate = useNavigate()
    const {setPostNewHs, setUser, user} = useContext(UserContext)
    const {title, difficulty, time, mode} = gameSettings
    const gameMode = JSON.parse(localStorage.getItem("hsMode"))
    const [score, setScore] = useState(0)
    const [target, setTarget] = useState(() => randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty}))
    const [startTimer, setStartTimer] = useState(false)
    const [newHs, setNewHs] = useState(false)
    const [timerFinished, setTimerFinished] = useState(null)    
    const [disabled, setDisabled] = useState(true) 
    const [scoreSound] = useSound(ScoreSound, {soundEnabled: sound})
    const [gameEndSound] = useSound(GameEndSound, {soundEnabled: sound, volume: 0.25})
    const [gameEndSoundNewHs] = useSound(NewHSSound, {soundEnabled: sound, volume: 0.25})
    const {
        state: {numTileInfo, equationInfo, ans, usedOp, numTileClicked, opTileClicked},
        NEW_TILES, RESET_TILES, UNDO_TILES, UPDATE_EQUATION
    } = useNumtiles()

    function handlePlayAgain() {
        setTimerFinished(false)
        setStartTimer(true)
        const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
        setTarget(tempTarget)
        NEW_TILES({difficulty, target: tempTarget})
        setNewHs(false)
        setDisabled(false)
        setScore(0)
    }

    function handleQuit() {
        if (isMobile && document.fullscreenElement) {
            document.exitFullscreen()
        }
        navigate("/")
    }

    useEffect(() => {
        NEW_TILES({difficulty, target})
        return () => {}
    }, [])

    useEffect(() => {
        if (ans === target) {
            const increaseScore = checkAns({difficulty, numTileInfo, opUsed: usedOp})
            if (increaseScore) {
                scoreSound()
                setScore(prevState => prevState + 1)
                const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
                setTarget(tempTarget)
                NEW_TILES({difficulty, target: tempTarget})
            } else {
                // add something saying, you must use all of the tiles! or something like that 
            }
        }
        return () => {}
    }, [ans])

    useEffect(() => { 
        let timerId;
        timerId = setInterval(() => {
            tempTime -= .5
            if (tempTime <= 0) {
                clearInterval(timerId)
                setDisabled(false)
                setStartTimer(true)
            }
        }, 500);
        return () => {clearInterval(timerId)}
    }, [])

    useEffect(() => {
        const tempTarget = randomNumberToMax({max: SETTINGS.MAX_TARGET_VALUE, difficulty})
        setTarget(tempTarget)
        NEW_TILES({difficulty, target: tempTarget})
        if (timerFinished && score > user.highscores[mode][gameMode.string]) {
            let tempUser = user
            setNewHs(true)
            setPostNewHs(true)
            tempUser.highscores[mode][gameMode.string] = score;
            if (user.username) {
                postToDB({score, user, gameString: gameMode.string, mode})
            }
            setUser(tempUser)
            gameEndSoundNewHs()
            setDisabled(true)
            setStartTimer(false)
        } else if (timerFinished) {
            gameEndSound()
            setDisabled(true)
            setStartTimer(false)
        }
        return () => {}
    }, [timerFinished])

    return (
        <Page>
            {timerFinished && 
                <EndModal 
                    handlePlayAgain={handlePlayAgain} 
                    handleQuit={handleQuit} 
                    score={score} newHs={newHs}
                    difficulty={title}
                />
            }
            <TopBar title={title} navigateTo={"/singleplayer"} isMobile={isMobile}/>
            <Timer startTimer={startTimer} time={time} setTimerFinished={setTimerFinished}/>    
                <Container $justify={"space-around"}>
                    <Score $size={"25px"} score={score}/>
                    {user.highscores && <Highscore $size={"25px"} highscore={user.highscores[mode][gameMode.string]}/>}
                </Container>
            <TargetContainer $flex={1}>
                <Target $size={"70px"} target={target}/>
            </TargetContainer>
            <Container $flex={1}>
                <Phrases $size={"20px"} score={score} phrases={inGamePhrases}/>
            </Container>
            <Container $flex={6} style={{alignItems: "flex-start"}}>
                <Gameboard 
                    equationInfo={equationInfo} numTileInfo={numTileInfo} 
                    numTileClicked={numTileClicked} opTileClicked={opTileClicked}
                    opUsed={usedOp} UNDO_TILES={UNDO_TILES}
                    UPDATE_EQUATION={UPDATE_EQUATION} RESET_TILES={RESET_TILES}
                    disabled={disabled} keyboardMap={keyboardMap}
                    isMobile={isMobile}
                />
            </Container>
        </Page>
    )
}

export default TimeTrial;