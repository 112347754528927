import { useNavigate } from "react-router-dom";
import GameSetting from "../../components/gamesetting/GameSetting";
import TopBar from "../../components/topbar/TopBar";
import { Button, Container, Page } from "../../global/styles/styles";
import { useEffect, useState } from "react";
import Description from "../../components/description/Description";

const Multiplayer = ({isMobile, isMobileSafari}) => {
    const navigate = useNavigate()
    const gameMode = JSON.parse(localStorage.getItem("hsModeMulti")) || {}
    const [gameSettings, setGameSettings] = useState({
        point: gameMode.point || "5", //5, 7, 10
        timeToSolve: gameMode.timeToSolve || 20, //20, 25, 30
        title: gameMode.title || "Buzzer",
        difficulty: gameMode.difficulty || "e",
        difficultyString: gameMode.difficultyString || "Easy",
        time: gameMode.time || 60,
        mode: gameMode.mode || "bzz",
    })
    const hsMode = {}
    hsMode["difficulty"] = gameSettings.difficulty
    hsMode["mode"] = gameSettings.mode
    hsMode["point"] = gameSettings.point

    function handleClick() {
        try {
            if (isMobile) {
                document.body.requestFullscreen()
            } else if (isMobileSafari) {
                Element.webkitRequestFullscreen()
            }
        } catch {}
        finally {
            localStorage.setItem("hsModeMulti", JSON.stringify(hsMode))
            navigate("/game", {state: {gameSettings}})
        }
    }
    
    useEffect(() => {
        switch (gameSettings.difficulty) {
            case "e":
                setGameSettings(prevState => ({...prevState, time: 60, timeToSolve: 20, difficultyString: "Easy"}))
                break;
            case "m":
                setGameSettings(prevState => ({...prevState, time: 90, timeToSolve: 25, difficultyString: "Normal"}))
                break;
            case "h":
                setGameSettings(prevState => ({...prevState, time: 120, timeToSolve: 30, difficultyString: "Hard"}))
                break;
        }
        return () => {}
    }, [gameSettings.difficulty])
    
    useEffect(() => {
        switch (gameSettings.mode) {
            case "bzz":
                setGameSettings(prevState => ({...prevState, title: "Buzzer"}))
                break;
            case "mtt":
                setGameSettings(prevState => ({...prevState, title: "Time Trial"}))
                break;
            case "pre":
                setGameSettings(prevState => ({...prevState, title: "Pressure"}))
                break;
        }
        return () => {}
    }, [gameSettings.mode])

    const difficultySetting = {
        title: "Difficulty",
        setting: "difficulty",
        buttons: [
            {
                text: "Easy",
                value: "e",
            },
            {
                text: "Normal",
                value: "m",
            },
            {
                text: "Hard",
                value: "h",
            },
        ]
    }

    const modeSetting = {
        title: "Game Mode",
        setting: "mode",
        buttons: [
            {
                text: "Buzzer",
                value: "bzz",
            },
            {
                text: "Time Trial",
                value: "mtt",
            },
            {
                text: "Pressure",
                value: "pre",
            },
        ]
    }

    const pointSetting3to7 = {
        title: "Points to Win",
        setting: "point",
        buttons: [
            {
                text: "3",
                value: 3,
            },
            {
                text: "5",
                value: 5,
            },
            {
                text: "7",
                value: 7,
            },
        ]
    }

    return (
        <Page>
            <TopBar title={"Multiplayer"} navigateTo={"/"}/>
            <Container $flex={1}>
            </Container>
            <Container $flex={3}>
                <GameSetting setGameSettings={setGameSettings} $active={gameSettings.mode} settings={modeSetting}/>
            </Container>
            <Container $flex={3}>
                <GameSetting setGameSettings={setGameSettings} $active={gameSettings.difficulty} settings={difficultySetting}/>
            </Container>
            <Container $flex={3}>
                { gameSettings.mode === "bzz" &&
                    <GameSetting setGameSettings={setGameSettings} $active={gameSettings.point} settings={pointSetting3to7}/>
                }
                { gameSettings.mode === "pre" &&
                    <GameSetting setGameSettings={setGameSettings} $active={gameSettings.point} settings={pointSetting3to7}/>
                }
            </Container>
            <Container $flex={6} $flexDirection={"column"}>
                <Description gameSettings={gameSettings}/>
            </Container>
            <Container $flex={4} $width={"70%"}>
                <Button $flex={1} onClick={handleClick}>Play</Button>
            </Container>
        </Page>
    )
}

export default Multiplayer;