import React, { useContext } from "react";
import { ContainerMidBar, TextMidBar } from "./styles";
import BackButton from "../backbutton/BackButton";
import { ContainerBackButton } from "../backbutton/styles";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../global/contexts/themecontext/ThemeContext";
import Timer from "../timer/Timer";
import { Container } from "../../global";

const MidBar = ({$size, time, title, navigateTo, startTimer, setTimerFinished, isMobile}) => {
    const theme = useContext(ThemeContext)
    const navigate = useNavigate()
    function handleClick() {
        if (isMobile && document.fullscreenElement) {
            document.exitFullscreen()
        }
        navigate(navigateTo || -1)
    }
    return (
        <ContainerMidBar>
            <ContainerBackButton $midBar={true} onClick={handleClick}>
                <BackButton size={"2em"} color={theme.myTheme.colors.title}/>
            </ContainerBackButton>
            <Container style={{transform: "rotate(180deg)"}}>
                <TextMidBar $size={$size}>
                    {title}
                </TextMidBar>
            </Container>
            {startTimer && <Timer time={time} startTimer={startTimer} setTimerFinished={setTimerFinished}/>}
            <Container>
                <TextMidBar $size={$size}>
                    {title}
                </TextMidBar>
            </Container>
        </ContainerMidBar>
    )
}

export default MidBar;