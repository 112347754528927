import React from "react";
import BotBarItem from "./BotBarItem";
import { IoHomeOutline, IoPersonOutline, IoPodiumOutline, IoSettingsOutline } from "react-icons/io5"
import { ContainerBotBar } from "./styles";

const BotBar = () => {
    const tabs = [
        {
            label: "Home",
            icon: <IoHomeOutline size={"1.25em"}/>,
            link: "/",
        },
        {
            label: "Leaderboard",
            icon: <IoPodiumOutline size={"1.25em"}/>,
            link: "/leaderboard",
        },
        {
            label: "Profile",
            icon: <IoPersonOutline size={"1.25em"}/>,
            link: "/profile",
        },
        {
            label: "Settings",
            icon: <IoSettingsOutline size={"1.25em"}/>,
            link: "/settings",
        },
    ]    
    const botTab = tabs.map(tab => <BotBarItem key={tab.label} {...tab}/>)

    return (
        <ContainerBotBar>
            {botTab}
        </ContainerBotBar>
    )
}

export default BotBar;