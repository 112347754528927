import styled from "styled-components";
import { Button, Container, Heading, Modal } from "../../global";

const HowToPlayContainer = styled.div`
    height: calc(100 * var(--vh));
    width: 100vw;
    background-color: rgba(100, 100, 100, .8);
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HowToPlayContent = styled(Container)`
    height: 85%;
    width: 90%;
    overflow: scroll;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
`;
    
    const HowToPlayModal = styled(Modal)`
    background-color: ${props => props.theme.background};
    border-color: ${props => props.theme.title};
    flex-direction: column;
    gap: 5px;
`;

const HowToPlayGif = styled.img`
    height: 300px;
    width: 180px;
`;

const HowToPlayButton = styled(Button)`
    height: 50px;
    min-height: 0px;
`;

const HowToPlayButtonContainer = styled(Container)`
    height: 70px;
`;

const HeaderText = styled(Heading)`
    font-size: 16px;
`;

const ContentText = styled(Heading)`
    font-size: 14px;
    text-align: left;
`;

export {
    ContentText,
    HeaderText,
    HowToPlayButton,
    HowToPlayButtonContainer,
    HowToPlayContainer,
    HowToPlayContent,
    HowToPlayGif,
    HowToPlayModal
};