import styled from "styled-components";
import { Container, Heading } from "../../global";

const UserHSText = styled(Heading)`
    flex: ${props => props.$flex};
`;

const ScoreContainer = styled(Container)`
    align-items: flex-start;
`;

const UserScoresContainer = styled(Container)`
    flex: 1;
    flex-direction: column; 
    justify-content: flex-start; 
    max-height: 45vh; 
    overflow: scroll;
`;

export { 
    ScoreContainer,
    UserHSText,
    UserScoresContainer
};