import React from "react";
import { ThemeButton } from "../styles";

const ThemeSwitch = ({theme, setTheme, $color, value}) => {
    function handleClickTheme() {
        setTheme(prevState => ({...prevState, color: value}))
        saveTheme()
    }
    function saveTheme() {
        let tempTheme = theme
        theme.color = value
        localStorage.setItem("Theme", JSON.stringify(tempTheme))
    }

    let active = false;
    if (theme.color === value) {
        active = true; 
    }

    return <ThemeButton $color={$color} $active={active} onClick={handleClickTheme}/>
}

export default ThemeSwitch;