import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Practice from "./Practice";
import LoadingGame from "../../components/loading/LoadingGame";
import TimeTrial from "./TimeTrial";
import { Page } from "../../global";
import MultiBuzzer from "./MultiBuzzer";
import LoadingMulti from "../../components/loading/LoadingMulti";
import MultiTimeTrial from "./MultiTimeTrial";
import MultiPressure from "./MultiPressure";

const Game = ({isMobile}) => {
    let tempTime = 1
    const location = useLocation()
    const { state } = location
    const gameSettings = state?.gameSettings || {        
        title: "Easy",
        difficulty: "e",
        time: 60,
        mode: "p",
    }
    const [loading, setLoading] = useState(true)
    const [loadComplete, setLoadComplete] = useState(false)

    function handleClickLoading() {
        setLoadComplete(true)
    }

    function loadedGame() {
        switch (gameSettings.mode) {
            case "p":
                return <Practice gameSettings={gameSettings} isMobile={isMobile}/>        
            case "timeTrial":
                return <TimeTrial gameSettings={gameSettings} isMobile={isMobile}/>        
            case "bzz":
                return <MultiBuzzer gameSettings={gameSettings} isMobile={isMobile}/>        
            case "mtt":
                return <MultiTimeTrial gameSettings={gameSettings} isMobile={isMobile}/>        
            case "pre":
                return <MultiPressure gameSettings={gameSettings} isMobile={isMobile}/>        
            default:
                break;
        }
    }

    useEffect(() => { 

        let timerId
        timerId = setInterval(() => {
            tempTime--
            if (tempTime <= 0) {
                clearInterval(timerId)
                setLoading(false)
            }
        }, 500)
        return () => {clearInterval(timerId)}
    }, [])

    return (
        <>
            {loadComplete ?  loadedGame() : 
                (gameSettings.mode === "p" || gameSettings.mode === "timeTrial") ?
                    <Page>
                        <LoadingGame gameSettings={gameSettings} dataReady={!loading} onClick={handleClickLoading}/>
                    </Page> 
                    : 
                    <Page>
                        <LoadingMulti gameSettings={gameSettings} dataReady={!loading} onClick={handleClickLoading}/>
                    </Page>
            }
        </>
    )
}

export default Game;