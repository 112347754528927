import styled from "styled-components";
import { Modal } from "../../global";

const MessageModalContainer = styled(Modal)`
    height: 15vh;
    min-width: min-content;
`;

export {
    MessageModalContainer
};