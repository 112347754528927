import { useState } from "react";
import { Button, Container, Heading, LineSpace, Modal } from "../../global";
import { UserHSText, UserScoresContainer } from "./styles";
import { postHighScore } from "./utils";
import ShareModal from "./ShareModal";
import { IoShareSocial } from "react-icons/io5";

const DifficultyHS = ({
    difficulty,
    mode, 
    modeString, 
    highscore = [], 
    setAllHS, 
    setModal, 
    title,
    user, 
    userStats
}) => {
    const [showShare, setShowShare] = useState(false)
    let hsString = ''
    let time = 0
    switch (difficulty) {
        case ("e"):
            hsString = "hse60"
            time = 60
            break
        case ("m"):
            hsString = "hsm90"
            time = 90
            break
        case ("h"):
            time = 120
            hsString = "hsh120"
            break
    }

    const scoreMap = highscore.map((score, index) => {
        return (
            <Container key={`${score.username}${score.value}`}>
                <UserHSText $flex={1}>{score?.rank}</UserHSText>
                <UserHSText $flex={1}>{score?.username}</UserHSText>
                <UserHSText $flex={1}>{score?.value}</UserHSText>
            </Container>
        )
    })

    function handlePost() {
        postHighScore({difficulty, setAllHS, time, user, setModal, mode})
    }

    return (
        <Container $flex={1} $flexDirection={"column"} $gap={"8px"}>
            {showShare && 
                <ShareModal 
                    difficulty={difficulty}
                    mode={mode}
                    highscore={user.highscores[mode][hsString]}
                    userStats={userStats}
                    modeString={modeString}
                    title={title}
                    setShowShare={setShowShare}
                />
            }
            <Container $flex={1}>
                <UserHSText $flex={1}>Rank</UserHSText>
                <UserHSText $flex={1}>Username</UserHSText>
                <UserHSText $flex={1}>Score</UserHSText>
            </Container>
            <UserScoresContainer>
                {scoreMap}
            </UserScoresContainer>
            <LineSpace/>
            { user.username ? 
                <Container $flex={1} $flexDirection={"column"} $gap={"5px"}>
                    <Container $gap={"15px"}>
                        <Button onClick={handlePost}>Post Your Score</Button>
                        <Button onClick={() => setShowShare(true)}>
                            <Container $gap={"5px"}>
                                Share <IoShareSocial/>
                            </Container>
                        </Button>
                    </Container>
                    <Heading>Username: {user.username}</Heading>
                    <Heading>Highscore: {user.highscores[mode][hsString] || 0}</Heading>
                    { userStats[mode][difficulty] &&                   
                            <Heading>Rank: {userStats[mode][difficulty]["rank"] || 0} (Top {userStats[mode][difficulty]["percentile"] || 0}%)</Heading>
                    }                
                </Container> 
                : 
                <Container $flex={1}>
                    <Heading>Sign in to post your score to the leaderboard!</Heading>
                </Container> 
            }
        </Container>
    )
}

export default DifficultyHS;