import { styled } from "styled-components";
import { colors } from "../../../global/styles/base";
import { Button } from "../../../global";

const OtherTileButton = styled(Button)`
    background-color: ${props => (props.$clicked || props.$disabledRR) === true ? props.theme.card : props.theme.background};
    color: ${props => (props.$clicked || props.$disabledRR) === true ? props.theme.lightText : props.theme.title};
    cursor: ${props => props.$disabledRR === true ? "default" : "pointer"};
    font-size: 16px;
    min-height: ${props => props.$multi === true ? "30px" : "40px"};
    max-height: 45px;
    height: ${props => props.$multi === true ? "4vh" : "10vw"};
    min-width: 75px;
    z-index: ${props => props.$buzzer === true ? 2 : 1};
    max-width: ${props => props.$buzzer === true ? "100%" : "100px"};
    width: ${props => props.$buzzer === true ? "100%" : "18vw"};
    @media (hover: hover) and (pointer: fine) and (${props => props.$disabled} = false) {
        &:hover {
            background-color: ${props => props.$clicked === true ? colors.HOVERED_GRAY : props.theme.primary };
            color: ${props => props.$clicked === true ? props.theme.lightText : props.theme.darkText};
        }
    }
`;

export {
    OtherTileButton,
};